import { DataGridPro} from '@mui/x-data-grid-pro';
import React, {useEffect, useState} from "react";
import {ProjectToSync} from "../../ps-types";
import {useCompanyId} from "../../core";
import {ClosableSection} from "../../ClosableSection";
import {CodeEditor} from "../../ui/CodeEditor";
import {SmallLoading} from "../../ui/Loading";
import {Button, Header, Segment} from "semantic-ui-react";
import {
  getProjectsToSync,
  simpleSyncActuals,
  syncExecutionProjects,
  syncProjects, triggerActualsSync
} from "./amProjectSync.client";
import {useMessages} from "../../ui/MessagesProvider";
import {GridToolbar} from "@mui/x-data-grid";
import {DateRange} from "../../lineitems-store/DateRange";
import {addMonths} from "../../ps-models";
import _ from "lodash";



export function ProjectSync() {

  const [projectsToSync, setProjectsToSync] = useState<ProjectToSync[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const companyId = useCompanyId();
  const [selected, setSelected] = useState<string[]>([]);
  const {info, error, clear} = useMessages();
  const [startDate, setStartDate] = useState<Date>(addMonths(new Date('2023-01-01'), -3));
  const [endDate, setEndDate] = useState<Date>(new Date());

  useEffect(() => {
    getProjectsToSync(companyId).then((res) => {
      setProjectsToSync(res)
      setLoading(false)
    })
  }, []);

  //Projects map to Table for MUIX GRid
  const rows = projectsToSync.map((project) => {
    return {
      id: project.projectId,
      ...project
    }
  });

  const columns = Object.keys(rows[0] || {}).map((key) => {
    return {
      field: key,
      headerName: key,
      width: 150
    }
  });


  const projectIds: string[] = []
  const query = `
  SELECT
              acx.CONTRACTID as projectId,
              DESCRIPTION as projectName,
              acx.LEGALENTITY as SPV,
              /*
          0 - Distributed Solar  :  Solar
          1 - Open Access Solar : Solar
          2 - Battery : Solar (Can be ignored as of now)
          3 - Wind : Wind
          4 - Hybrid :  Wind*/
              OAPLANT as projectType,
              acx.TENURE as projectLife,
              STARTINGTARIFF as tariff,
              ESCALATION as escalation,
              AVG(fp3.YIELD) averageTemperature,


              AVG(estpr.YIELD)/100 firstYearPR,

              ppp.ESTIMATEDYIELDY1 as estimatedYieldYear1,
              -- Ignoring this two for now, althought according to the mapping this are the ones for year 1
--     AVG(esty.YIELD) estimatedYield,
--     AVG(esty.YIELD) windYieldYear1,

              (ppp.DEGRADATIONY1 + ppp.DEGRADATIONY2)/200 degradation,
              ppp.INCOMETAXRATE/100 as incomeTaxRate,
              ppp.BOOKDEPRECIATIONRATE/100 as depreciationAsCoAct,
              ppp.ITDEPRECIATIONRATE/100 as depreciationAsItAct,
              ppp.ITACCELERATEDDEPRECIATIONRATE/100 as additionalDepreciationRate,
              ppp.DEBTRATIO/100 as loanAmount,
              ppp.INTERESTRATE/100 as interestRate,
              ppp.DEBTTENOR as debtTenor,
              ppp.PROMOTEREQUITY as promoterEquity,
              ppp.PROMOTERDEBT as promoterDebt,
              ppp.INTERESTRATEPROMOTERDEBT as interestRatePromoterDebt,


              AVG(fp2.YIELD) windYieldAC,
              AVG(fp5.YIELD) averageAirDensity,

              acx.PLANTCAPACITYAC as acCapacity,
              acx.PLANTCAPACITYDC as dcCapacity,
              ppp.SECURITYDEPOSIT as securityDeposit

          FROM AcxContractBillingSaleOfPowerStaging AS acx
                   LEFT JOIN FPELPlantPerformanceParametersStaging AS ppp
                             ON acx.CONTRACTID = ppp.CONTRACTID
                   LEFT JOIN FPELEstimatedYieldCopy3Staging AS fp3
                             ON ppp.RECID1 = fp3.PARENTRECID
                   LEFT JOIN FPELEstimatedPRStaging AS estpr
                             ON ppp.RECID1 = estpr.PARENTRECID
                   LEFT JOIN FPELEstimatedYieldStaging AS esty
                             ON ppp.RECID1 = esty.PARENTRECID
                   LEFT JOIN FPELEstimatedYieldCopy2Staging AS fp2
                             ON ppp.RECID1 = fp2.PARENTRECID
                   LEFT JOIN FPELEstimatedYieldCopy5Staging AS fp5
                             ON ppp.RECID1 = fp5.PARENTRECID
              
          ${projectIds.length > 0 ? `WHERE acx.CONTRACTID IN (${projectIds.map(p => `'${p}'`).join(',')}) AND CONTRACTSTATUS = 1` : 'WHERE CONTRACTSTATUS = 1'}
          
          GROUP BY acx.CONTRACTID, DESCRIPTION, STARTINGTARIFF, ESCALATION, OAPLANT,
                   acx.TENURE, acx.PLANTCAPACITYDC, ppp.DEGRADATIONY1, ppp.DEGRADATIONY2,
                   ppp.INCOMETAXRATE, ppp.BOOKDEPRECIATIONRATE, ppp.ITDEPRECIATIONRATE,
                   ppp.ITACCELERATEDDEPRECIATIONRATE, ppp.DEBTRATIO, ppp.INTERESTRATE,
                   ppp.DEBTTENOR, ppp.PROMOTEREQUITY, ppp.PROMOTERDEBT, ppp.INTERESTRATEPROMOTERDEBT,
                   acx.PLANTCAPACITYAC, acx.LEGALENTITY, ppp.ESTIMATEDYIELDY1, ppp.SOLARGISYIELD, ppp.SECURITYDEPOSIT
  `;



  const handleSimpleProjectSync = async () => {
      //Make batches of projectIds from selected
       let batches = _.chunk(selected, 5)

        for(let i = 0; i < batches.length; i++) {
          let batch = batches[i]
          console.log("Syncing projects", batch)
          setLoading(true);

          try {
            await syncProjects(companyId, batch)
            info("Projects Synced " + batch.map(p => p).join(', '))
          } catch (e: any) {
            error(`Error syncing projects ${e.message}`)
          } finally {
            setLoading(false)
          }
        }

  }


  const handleSimpleActualsSync = async () => {
    clear();
    console.log("Syncing projects", selected);
    //Find SPV of selected projects
    let spvs = projectsToSync.filter(p => selected.includes(p.projectId)).map(p => p.spv as string);

    //Create a list of (from, to) dates partitioned by 2 years
    let dateRanges = []

    let start = startDate.getTime()

    while (start < endDate.getTime()) {
      let end = addMonths(new Date(start), 12).getTime()
      dateRanges.push({start, end})
      start = end
    }

    for(let i = 0; i < dateRanges.length; i++) {
      let range = dateRanges[i]
      console.log("Syncing SPVs", spvs, new Date(range.start), new Date(range.end))
      setLoading(true)
      try {
        await simpleSyncActuals(companyId, selected, "project-version", {dateFrom: range.start, dateTo: range.end});
        info(`Project Actuals Synced for chunk ${i} out of ${dateRanges.length}`)
      } catch (e: any) {
        error(`Error syncing projects ${e.message}`)
      } finally {
        setLoading(false)
      }
    }
    info(`Project Actuals Sync fully complete`)

  }

  const handleExecutionSync = () => {
    if(!selected.length){
      error("Please select at least one project.");
      return;
    }
    setLoading(true)
    syncExecutionProjects(companyId, selected).then(() => {
      setLoading(false)
      info("Projects Synced")
    }).catch((e) => {
      setLoading(true)
      const domainError = e.response.data.split('.')[0]
      error(domainError)
    }).finally(() => {
      setLoading(false);
    });
  }

  const handleActualsSync = () => {
    console.log("Syncing projects", selected);
    setLoading(true)
    triggerActualsSync(companyId, selected, 'project-version', {dateFrom: startDate.getTime(), dateTo: endDate.getTime()}).then(() => {
      info("Project Actuals Synced")
      setLoading(false)
    }).catch((e) => {
      error(`Error syncing projects ${e.message}`)
      setLoading(false)
    });
  }

  return (
    <div>
      <div>
        <Header floated="left" as="h3">Projects to Sync</Header>
         <Button primary floated="right" content={"Simple Project Sync"} onClick={handleSimpleProjectSync}/>
        <Button primary floated="right" content={"Project Execution Sync"} onClick={handleExecutionSync}/>
        <div style={{clear: 'both'}}></div>
        <div>
          <DateRange initialStartDate={startDate}
                     initialEndDate={endDate}
                     onSelect={
                       (start, end) => {
                         setStartDate(start)
                         setEndDate(end)
                       }
                     }
          />
          <Button content={"Simple Actuals Sync"} onClick={handleSimpleActualsSync}/>
          <Button content={"Actuals Sync"} onClick={handleActualsSync}/>
        </div>
      </div>

      <div style={{clear: 'both'}}></div>

      <ClosableSection opened={false} title={"Data Source Config"} level={"title-bar"}><Segment>
        <strong>Data Source:</strong> SqlServer.FourthPartner <br/>
        <strong>Query:</strong>
        <CodeEditor code={query} language={"sql"} onChanged={() => {
        }}/>
      </Segment>
      </ClosableSection>
      <p></p>

        {loading && <SmallLoading message="loading projects"/>}

        <div style={{width: '100%'}}>
          <DataGridPro
              density={'compact'}
              rows={rows}
              columns={columns}
              pagination={true}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 30,
                  },
                }
              }}
              autoHeight
              columnBuffer={2} columnThreshold={2}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                }
              }}
              checkboxSelection
              disableRowSelectionOnClick
              onRowSelectionModelChange={(selection) => {
                console.log(selection)
                setSelected(selection as string[])
              }}
          />
        </div>
      </div>);
}