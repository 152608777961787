import {Dimmer, Loader, Segment} from "semantic-ui-react";
import React, {useMemo} from "react";

export function PageLoading({message}: {message?: string}) {
  return <Dimmer active page inverted>
    <Loader className="double purple" active size="huge" content={
      <>{message || 'Loading...'}</>
    } />
  </Dimmer>
}
export function Loading({message}: {message?: string}) {
  return <Dimmer page={false} active={true} inverted><Loader active={true} content={message || `Loading, Please wait...`}/></Dimmer>
}

export function SmallLoading({message}: {message?: string}) {
  return <div >
      <Loader active={true} inline content={message || `Loading, Please wait...`}/>
  </div>
}

export function LoadingBlock({message, loadingMessages}: {message?: string, loadingMessages?: string[]}) {
  return useMemo(() => <LoadingBlockView message={message} loadingMessages={loadingMessages} />, [message, loadingMessages]);
}

export function LoadingBlockView({message, loadingMessages}: {message?: string, loadingMessages?: string[]}) {
  let [messageIndex, setMessageIndex] = React.useState(0);
  let [messageTimeout, setMessageTimeout] = React.useState<any>();

  React.useEffect(() => {
    setMessageTimeout(setTimeout(() => {
      setMessageIndex( Math.min(messageIndex + 1, loadingMessages?.length || 0));
      console.info("LoadingBlock: messageIndex", messageIndex)
    }, 5000));
    return () => {
      clearTimeout(messageTimeout);
    }
  }, [messageIndex]);

  let fullMessage = `${message || ""}  ${(loadingMessages && messageIndex < loadingMessages.length ? loadingMessages[messageIndex] : "" )}`;

  return <div style={{padding: "40px"}}><Loading message={ fullMessage } /></div>
}