import {
  cleanUpStringForWritingToCSV,
  compareNormalized,
  convertToNumberOrString,
  convertUnixTimeToExcelNumberTime,
  findInArrayFromEnd,
  normalizeString,
  valueAsNumber
} from "../line-item-utils/coding.utils";
import {ValueType} from "../line-items";
import {groupBy, indexBy, sort} from "ramda";
import {GROUPING_LINE_ITEM_KEY, GROUPING_PARENT_REFERENCE_KEY} from "../LineItemDataSet";
import {CELL_TEXT_FOR_ITEM_EXCLUDED_FROM_ROW_AGGREGATE, ROW_AGGREGATE_COLUMN_ID} from "./query-runner";
import {DateFinancialValueTypes, FinancialValueType, VALUE_TYPE_KEY} from "../fin-math/FinancialValueTypes";
import * as XLSX from 'xlsx';
import {STORE_VALUE_DECIMALS_KEY} from "../line-item-utils/StoreValueDecimals";
import {getExcelFormattingCode} from "../formatting";

export interface ResultCell {
  text: string
  columnId: string
  type: 'time' | 'header' | 'lineItemValue' |  'metadata' | 'ID',
  value: ValueType
  error?: string
}


export type LineItemRow  = {
  [columnId: string]: ResultCell
} & {
  name : ResultCell
  path?: string[]
  rowFormatterType?: string
  extraClasses?: string,
  hidden?: boolean,
  order?: number,
}

export interface LineItemColumn {
  type: 'time' | 'header' | 'metadata-header'
  columnId: string
  text: string,
  order?:number
}

export interface TimeColumn extends LineItemColumn {
  time: number
  timeIndex: number
}

export interface QueryResultData {
  rows: LineItemRow[]
  columns: LineItemColumn[]
}

export class QueryResult {
  _refs: Record<string, LineItemRow> | undefined
  _colsById: Record<string, LineItemColumn> | undefined

  constructor(public data: QueryResultData) {
    //Todo: This should be optimized
    this.addPaths();
  }

  defaultExclusions() {
  }

  static emptyTable() {
    return new QueryResult({
      rows: [],
      columns: []
    })
  }
  get rows() { return this.data.rows }
  get columns() { return this.data.columns }
  unwrapData() { return this.data }

  row(name: string): LineItemRow | undefined {
    return this.refs()[normalizeString(name)]
  }

  addSectionTitle(title: string, rowId?: string) {
    let uniqueName = rowId || `${title}-${this.data.rows.length}`;

    this.data.rows.push({
      name: {
        value: uniqueName,
        text: title,
        columnId: "name",
        type: "header"
      },
      path: [uniqueName],
      rowFormatterType: 'header'
    } as LineItemRow)
    this._refs = undefined;
  }

  addSection(results: QueryResult, title?: string) {
    this.data.columns = results.data.columns;
    if(title) {
      this.addSectionTitle(title)
    }
    this.data.rows.push(...sort(r => r.order || 0 ,results.data.rows));
    this._refs = undefined;
  }

  setFormatting(name: string, type: string) {
    let row = this.row(name)
    if(row) {
      row.rowFormatterType = type
    }
    this._refs = undefined;
  }

  setOrder(list: string[]) {

    let order = 0
    for(let name of list) {
      let row = this.row(name)
      if(row) {
        row.order = (row.order || 0) + order++
      }
    }

    this.data.rows = sort(r => r.order || 0, this.data.rows)
    this._refs = undefined;
  }

  setOrderByColumnId(list: string[]){
      let order = 0;
      for(let name of list) {

        let column = this.columnById(normalizeString(name));
        if(column) {
          column.order = (column.order || 0) + order++
        }
      }
      this.data.columns = sort((c1, c2) => ((c1?.order || 0) - (c2?.order || 0)), this.data.columns)
  }

  columnById(columnId: string): LineItemColumn | undefined {

    if(!this._colsById) {
      this._colsById = indexBy(c => c.columnId, this.columns)
    }

    return this._colsById[columnId];
  }

  rowLineItemName(row: LineItemRow) {
    return row.name.value.toString()
  }

  lineItemName(rowIndex: number) {
    return this.data.rows[rowIndex].name.value.toString()
  }

  columnValue(rowIndex: number, columnId: string) {
    let row = this.data.rows[rowIndex]
    return row[normalizeString(columnId)]?.value || row[`fields.${normalizeString(columnId)}`]?.value
  }

  column(rowIndex: number, columnId: string) {
    let row = this.data.rows[rowIndex]
    return row[normalizeString(columnId)]
  }

  rowColumn(row: LineItemRow | string, columnId: string) {
    if(typeof row === "string") {
      return  this.row(row)?.[normalizeString(columnId)]
    }
    return row?.[normalizeString(columnId)]
  }

  rowColumnValue(row: LineItemRow | string, columnId: string) {
    return this.rowColumn(row, columnId)?.value
  }

  columnValueAsString(rowIndex: number, columnId: string) {
    return this.columnValue(rowIndex, columnId)?.toString() || ""
  }

  columnValueAsNumber(rowIndex: number, columnId: string): number {
    return valueAsNumber(this.columnValue(rowIndex, columnId) || 0)
  }

  nthSlotOf(name: string, index: number = 0) {
    let obj = this.row(name)

    if(!obj) {
      return undefined;
    }

    let columnId = this.columns.filter(c => c.type === "time")[index]?.columnId

    if(!columnId) {
      return undefined;
    }

    return obj[columnId]
  }

  rowValues(name: string): ValueType[] {
    const obj = this.row(name)
    if(!obj) {
      return [];
    }
    return this.columns.filter(c => c.type === 'time').map(c => obj[c.columnId]?.value)
  }


  firstTimeSlotValueOf(name: string) {
    return this.nthSlotOf(name)?.value
  }

  firstTimeSlotTextOf(name: string) {
    return this.nthSlotOf(name)?.text || ""
  }

  firstTime() {
    return (this.columns.filter(c => c.type === "time")[0] as TimeColumn)?.time;
  }

  lastTimeSlotOf(name: string) {
    let obj = this.row(name)

    if(!obj) {
      return undefined;
    }

    let lastColumnId = findInArrayFromEnd(this.columns, c => c.type === "time")?.columnId

    if(!lastColumnId) {
      return undefined;
    }

    return obj[lastColumnId]
  }

  lastTimeSlotTextOf(name: string) {
    return this.lastTimeSlotOf(name)?.text || ""
  }

  lastTimeSlotValueOf(name: string) {
    return this.lastTimeSlotOf(name)?.value
  }

  //Converts results to a 2x2 matrix of strings
  toSimpleTable() {
    let columns = this.data.columns
    let rows = this.data.rows
    let table: string[][] = []

    let header = columns.map(c => c.text)
    table.push(header)

    for(let row of rows) {
      let rowValues = columns.map(c => row[c.columnId]?.text || "")
      table.push(rowValues)
    }

    return table
  }

  toSimpleTableValues() {
    let columns = this.data.columns
    let rows = this.data.rows
    let table: (number | string)[][] = []

    let header = columns.map(c => c.text)
    table.push(header)

    for(let row of rows) {
      let rowValues = columns.map(c => {
        if(c.columnId.includes('ppsr')){ // Hack to interpret ppsr fields to be string
          return convertToNumberOrString(row[c.columnId]?.value).toString();
        }
        if(row[`${c.columnId}-store_valuetype`] && DateFinancialValueTypes.includes(normalizeString(row[`${c.columnId}-store_valuetype`]?.text) as any)){
          let val = row[c.columnId]?.value;
          // Converts to excel friendly format i.e. seconds elapsed since epoch.
          /* Tech notes on this
          * In the case of Excel they chose a different zero-time and step (because who wouldn't like variety in technical details?). So Excel counts days since 24 hours before 1/1/1900 UTC/GMT. So 25569 corresponds to 1/1/1970 00:00 UTC/GMT and 25570 to 2/1/1970 00:00.
          * Now if you also note that we have 86400 seconds per day (24 hours x60 minutes x60 seconds) and you will understand what this formula does: A1/86400 converts seconds to days and +25569 adjusts for the offset between what is zero-time for Unix and what is zero-time for Excel.
          * */
          return convertUnixTimeToExcelNumberTime(+convertToNumberOrString(val));
        }
        return convertToNumberOrString(row[c.columnId]?.value)
      }
      )
      table.push(rowValues)
    }

    return table
  }

  toStringTable() {
    let columns = this.data.columns
    let rows = this.data.rows
    let columnWidths = columns.map(c => c.text.length)
    let table: string[][] = []

    rows.sort((a, b) => a.name.text.localeCompare(b.name.text));

    for(let row of rows) {
      let rowValues = columns.map(c => row[c.columnId]?.text || "")
      table.push(rowValues)
      for(let i = 0; i < rowValues.length; i++) {
        columnWidths[i] = Math.max(columnWidths[i], rowValues[i].length)
      }
    }

    let header = columns.map(c => c.text)
    table.unshift(header)

    let lines = table.map(row => row.map((cell, i) => cell.padEnd(columnWidths[i])).join(" | "))
    return lines.join("\n")
  }

  private refs(): Record<string, LineItemRow> {
    if (this._refs === undefined) {
      this._refs = {}
      for (const row of this.data.rows) {
        this._refs[normalizeString(row.name.value as string)] = row
      }
    }
    return this._refs
  }

  updateRow(row: LineItemRow, rowIndex: number) {
    this.data.rows[rowIndex] = row
    if(this._refs) {
      this._refs[normalizeString(row.name.value as string)] = row
    }
  }


  rowsByCName(cname: string) {
    return this.data.rows.filter(r => {
      let name = r["fields.cname"]?.text || r.name.text
      return compareNormalized(name, cname)
    })
  }
  showFirstValueOnly(name: string) {
    let names = this.rowsByCName(name);

    for(let row of names) {
      let firstCell = this.nthSlotOf(row.name.value as string, 0)
      if(firstCell) {
        for(let column of this.data.columns) {
          if(column.type === "time") {

            delete row[column.columnId]
          }
        }
        row[firstCell.columnId] = firstCell
      }
    }

    this._refs = undefined;
  }

  collapsibleBy(fieldName: string) {
    this.data.rows.forEach((row, index) => {
      let liName = normalizeString(row.name.value as string);
      let parent = row[normalizeString(fieldName)]?.text;

      //Add parent row
      if(parent && !this.row(parent)) {
        this.addSectionTitle(parent, normalizeString(parent));
      }

      if(parent) {
        row.path = [`${normalizeString(parent)}`, liName]
      } else {
        row.path = [liName];
      }

    });

    this._refs = undefined;
  }



  addColumns(resultWithExtraColumns: QueryResult) {

    //Add new time columns
    let newColumns = resultWithExtraColumns.data.columns.filter(c => c.type === "time")
    this.data.columns.push(...newColumns)

    // //add related columns to each row
    for(let rowWithExtraColumnData of resultWithExtraColumns.rows) {
      let row = this.row(rowWithExtraColumnData.name.value as string)
      if(row) {
        for(let column of newColumns) {
          row[column.columnId] = rowWithExtraColumnData[column.columnId]
        }
      }
    }

    this.rebuildIndexes()

  }

  sectionsBy(fieldName: string, secondaryFieldName: string = "") {
    let table = QueryResult.emptyTable();


    let groups = groupBy(r =>
      r[fieldName]?.text || r[secondaryFieldName]?.text || r.name.text, this.data.rows);



    table.data.columns = this.data.columns;

    for(let groupName of Object.keys(groups)) {
      if(groups[groupName].length === 0) continue;

      if(groups[groupName].length > 1) table.addSectionTitle(groupName);
      table.data.rows.push(...groups[groupName])
    }

    this.data = table.data;
  }

  hideByCNames(cnames: string[]){
    let queryResult = new QueryResult({rows:this.data.rows, columns:this.data.columns})
    const cnamesNormalized = cnames.map(normalizeString);
    queryResult.data.rows = this.data.rows.filter(r => {
      let name = r["fields.cname"]?.text || r.name.text
      return !cnamesNormalized.includes(normalizeString(name))
    })
    this.data = queryResult.data;
  }

  rebuildIndexes() {
    this._refs = {}
    for (const row of this.data.rows) {
      this._refs[normalizeString(row.name.value as string)] = row
    }
  }

  addPaths() {
    this.data.rows.forEach((row, index) => {
      let liName = normalizeString(row.name.value as string);
      if(row[normalizeString(GROUPING_LINE_ITEM_KEY)]) {
        row.path = [`${normalizeString(liName)}`];
      } else {
        let parent = row[normalizeString(GROUPING_PARENT_REFERENCE_KEY)]?.text;
        row.path = parent ? [`${normalizeString(parent)}`, liName] : [liName];
        }
      });

  }


  addSeparatorsAfter(name: string, field?:string) {

    this.data.rows.forEach((row, index) => {
      if(!field) {
        let liName = row.name.text || "";
        if(!compareNormalized(liName, name)) return;
      } else {
        let value = row[normalizeString(field)]?.text || "";
        if(!compareNormalized(value, name)) return;
      }
      row.rowFormatterType = `with-separator-after`;
    });
    this.rebuildIndexes();
  }

  setLabelField(field: string) {

    this.data.rows.forEach((row, index) => {
      if(field === "$name") {
        row.name.text = row.name.value as string;
        return;
      }
      let value = row[normalizeString(field)]?.text || "";
      if(!value) return;
      row.name.text = value;
    });
    this.rebuildIndexes();
  }

  sendToBottom(name: string) {
    let row = this.row(name);
    if(row) {
      this.data.rows = this.data.rows.filter(r => r !== row);
      this.data.rows.push(row);
      this.rebuildIndexes();
    }
  }
  sortBy(field: string) {
    this.data.rows = sort((a, b) => {
      let aValue = a[normalizeString(field)]?.text || "";
      let bValue = b[normalizeString(field)]?.text || "";
      return aValue.localeCompare(bValue);
    }, this.data.rows);
    this.rebuildIndexes();
  }
  showNColumns(n: number) {
    this.data.columns = this.data.columns.slice(0, n);
  }


  trimNullColumns() {
    //Remove columns when all of the rows in that colum are null
    let columnsToRemove: string[] = [];
    for(let column of this.data.columns) {
      let hasValue = false;
      for(let row of this.data.rows) {

        if(row.name.value === "store_numberOfStores") {
          continue;
        }

        if(row[column.columnId]?.value) {
          hasValue = true;
          break;
        }
      }
      if(!hasValue) {
        columnsToRemove.push(column.columnId);
      }
    }
    this.data.columns = this.data.columns.filter(c => !columnsToRemove.includes(c.columnId));

  }

  trimLeadingColumnsWithZeroValue(){
    this.trimContiguousColumnsWithZeroValue("leading");
  }

  trimTrailingColumnsWithZeroValue(){
    this.trimContiguousColumnsWithZeroValue("trailing");
  }

  private trimContiguousColumnsWithZeroValue(direction:"leading" | "trailing"){
    let columnsToRemove: string[] = [];
    let allColumns = direction === "trailing" ? this.data.columns.reverse() : this.data.columns
    for(let column of allColumns) {
      let hasNonZeroValue = false;
      if(column.columnId === 'name' || column.columnId === ROW_AGGREGATE_COLUMN_ID) continue;
      for(let row of this.data.rows) {

        if(row.name.value === "store_numberOfStores") {
          continue;
        }
        if(row[column.columnId]?.value !==0) {
          hasNonZeroValue = true;
          break;
        }
      }
      if(!hasNonZeroValue) {
        columnsToRemove.push(column.columnId);
      } else {
        break;
      }
    }
    this.data.columns = this.data.columns.filter(c => !columnsToRemove.includes(c.columnId));
  }

  trimRowsWithZeroValue(){
    let rowsToRemove: string[] = [];
    for(let row of this.data.rows){
      let hasAllZeroValues = true;
      if(row.name.value === "store_numberOfStores") {
        continue;
      }

      //Check aggregate column only if it is available for the item
      if((row[ROW_AGGREGATE_COLUMN_ID] && row[ROW_AGGREGATE_COLUMN_ID].text !== CELL_TEXT_FOR_ITEM_EXCLUDED_FROM_ROW_AGGREGATE) && row[ROW_AGGREGATE_COLUMN_ID]?.value !==0) {
        hasAllZeroValues = false;
        continue;
      }

      for(let column of this.data.columns){
        if(column.columnId === 'name' || column.columnId === ROW_AGGREGATE_COLUMN_ID) continue;
        if(row[column.columnId]?.value !==0) {
          hasAllZeroValues = false;
          break;
        }
      }
      if(hasAllZeroValues) {
        rowsToRemove.push(row.name.text);
      }
    }

    this.data.rows = this.data.rows.filter(r => !rowsToRemove.includes(r.name.text))
  }


  overrideRowTitle(rowName: string, label: string) {
    let row = this.row(rowName);
    if(row) {
      row.name.text = label;
    }
  }

  withTimeRange(start: number, end: number) {

    //Filter out columns outside of the time range
    let newColumns = this.data.columns.filter(c => {
      if(c.type !== "time") {
        return true;
      }
      let time = (c as TimeColumn).time;
      return time >= start && time <= end;
    });

    return new QueryResult({
      rows: this.data.rows,
      columns: newColumns
    });

  }

  toCsv() {

    let columns = this.data.columns
    let rows = this.data.rows
    let columnNames = columns.map(c => cleanUpStringForWritingToCSV(c.text))
    let lines = [columnNames.join(",")]

    let previousParent = "";

    let groupNames = rows.filter(r => r[normalizeString(GROUPING_LINE_ITEM_KEY)]?.text).map(r => r.name.text);

    let groupNameReverseIndex = groupNames.reduce((acc, name, index) => { acc[name] = index; return acc },
      {} as Record<string, number>);

    rows.sort((a, b) => {
      let aGroup = a[normalizeString(GROUPING_PARENT_REFERENCE_KEY)]?.text;
      let bGroup = b[normalizeString(GROUPING_PARENT_REFERENCE_KEY)]?.text;
      if(aGroup && bGroup) {
        return groupNameReverseIndex[aGroup] - groupNameReverseIndex[bGroup];
      }
      return 0;
    });

    //Sort rows by group name


    for(let row of rows) {
      if(row.hidden) {
        continue;
      }

      let isGroup = row[normalizeString(GROUPING_LINE_ITEM_KEY)]?.text;

      if(isGroup) {
        continue;
      }

      let parent = row[normalizeString(GROUPING_PARENT_REFERENCE_KEY)]?.text;

      if(parent && parent !== previousParent) {
        let parentRow = this.row(parent)
        if(parentRow) {
          let parentRowValues = columns.map(c => parentRow?.[c.columnId]?.text);
          let parentLine = parentRowValues.map(v => cleanUpStringForWritingToCSV(v?.toString() || "")).join(",");
          lines.push(parentLine);
          previousParent = parent;
        }
      }

      //If this is a values row, add the group name at the top

      let rowValues = columns.map(c => row[c.columnId]?.text)
      let line = rowValues.map(v => cleanUpStringForWritingToCSV(v?.toString() || "")).join(",")
      lines.push(line)
    }



    return lines.join("\n")
  }

  toExcel() {
    let columns = this.data.columns;
    let rows = this.data.rows;

    // Prepare the data for the worksheet
    let sheetData = [];

    // Add column headers
    let columnNames = columns.map(c => c.text);
    sheetData.push(columnNames);

    let previousParent = "";

    let groupNames = rows.filter(r => r[normalizeString(GROUPING_LINE_ITEM_KEY)]?.text).map(r => r.name.text);
    let groupNameReverseIndex = groupNames.reduce((acc: any, name, index) => { acc[name] = index; return acc }, {});

    // Sort rows by group name
    rows.sort((a, b) => {
        let aGroup = a[normalizeString(GROUPING_PARENT_REFERENCE_KEY)]?.text;
        let bGroup = b[normalizeString(GROUPING_PARENT_REFERENCE_KEY)]?.text;
        if (aGroup && bGroup) {
            return groupNameReverseIndex[aGroup] - groupNameReverseIndex[bGroup];
        }
        return 0;
    });

    // Add data rows
    for (let row of rows) {
        if (row.hidden) {
            continue;
        }

        let isGroup = row[normalizeString(GROUPING_LINE_ITEM_KEY)]?.text;

        if (isGroup) {
            continue;
        }

        let parent = row[normalizeString(GROUPING_PARENT_REFERENCE_KEY)]?.text;

        if (parent && parent !== previousParent) {
            let parentRow = this.row(parent);
            if (parentRow) {
                let excelNumFmt = getExcelFormatCodeForLineItemRow(parentRow);
                let parentRowValues = columns.map(c => getExcelCellObjectFromResultCell(parentRow?.[c.columnId], excelNumFmt));
                sheetData.push(parentRowValues);
                previousParent = parent;
            }
        }

        // Add the current row values
        let excelNumFmt = getExcelFormatCodeForLineItemRow(row);
        let rowValues = columns.map(c => getExcelCellObjectFromResultCell(row[c.columnId], excelNumFmt));
        sheetData.push(rowValues);
    }

    // Create a worksheet and a workbook
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData); // Convert array of arrays to worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

    return XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
}
}

function getExcelFormatCodeForLineItemRow(row: LineItemRow){
  let rowValueType = row?.[VALUE_TYPE_KEY]?.value as FinancialValueType;
  let rowDecimalPlaces = row[STORE_VALUE_DECIMALS_KEY]?.value !==undefined ? parseInt(row[STORE_VALUE_DECIMALS_KEY]?.value as string) : undefined;
  if(rowValueType){
    return getExcelFormattingCode(rowValueType, rowDecimalPlaces)
  }
  return null;
}

function getExcelCellObjectFromResultCell(cell: ResultCell | undefined, numFmt: string | null){
  if(!cell){
    return "";
  }
  let value = cell?.value;
  let cellText = cell?.text || "";
  let cellType = 's';
  if(typeof value === "number"){
    cellType = 'n';
  } else if(typeof value === "boolean"){
    cellType = 'b';
  } else if(typeof value === "string"){
    cellType = 's';
  }
  if(cellType === 's'){
    return cellText
  }
  return {t: cellType, v: value, w: cellText, z: numFmt}
}
