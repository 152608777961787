  import {AmProject, getDefaultProjectVersion, getStoreId} from "../../ps-types";
import {authStorage} from "../../auth";
import {getAmProjectConfig, TimeUnits} from "../../ps-models";
import {useLineItemsStore} from "../../lineitems-store/LineItemsStore.hook";
import React, {useState} from "react";
import {Message, Segment, Select} from "semantic-ui-react";
import {LoadingBlock} from "../../ui/Loading";
import {StoreQuery} from "../../ps-models/lineitems-store";
import {LineItemsTableWithFormulas} from "../../lineitems-store/LineItemsTableView";

export function StorePreview({project}: { project: AmProject }) {

  let projectVersion = getDefaultProjectVersion(project);
  const company = authStorage.getCompany();
  let {collection} = getAmProjectConfig(company);
  let storeId = getStoreId(projectVersion.projectId, projectVersion.versionId);
  let store = useLineItemsStore(storeId, collection);
  let [granularity, setGranularity] = useState<TimeUnits>("years");

  if (!projectVersion) {
    return <Message info>You should upload a project file</Message>
  }

  if (!store) {
    return <LoadingBlock/>
  }

  let table = store.query(StoreQuery.all().withTimeIndex(
    store.timeIndex.withGranularity(granularity)
  ), {withMetadata: []} );

  return <Segment>
    <Select
      placeholder='Select granularity'
      value={granularity}
      options={[
        {key: 'days', value: 'days', text: 'Day'},
        {key: 'weeks', value: 'weeks', text: 'Weeks'},
        {key: 'months', value: 'months', text: 'Month'},
        {key: 'quarters', value: 'quarters', text: 'Quarters'},
        {key: 'years', value: 'years', text: 'Year'},
      ]}
      onChange={(e, {value}) => {
        setGranularity(value as TimeUnits);
      }}
    />
    <LineItemsTableWithFormulas
      store={store}
      queryResult={table}
    />
  </Segment>

}