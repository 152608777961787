import psPlatformClient from "../psPlatformClient";
import {Company, CompanyConfig, ConfigType, ProjectTemplateConfig, WhatIfScenarioConfig, AMCompanyModel} from "../ps-types";
import {AxiosError, AxiosResponse} from "axios";
import {DomainError} from "../ps-models";
import {ConfigEntry} from "../ps-types/CompanyConfigs";


export const getCompanies = async (): Promise<Company[]> => {
    const result = await psPlatformClient<null, Company[]>({method: 'get', url: '/companies'})
    return result.data;
}

export const getCompany = async (companyId: string): Promise<Company> => {
    const result = await psPlatformClient<null, Company>({method: 'get', url: `/company/${companyId}`})
    return result.data;
}

export const assignScenarioToCompany = async (companyId: string, scenarioId: string): Promise<Company> => {
    return await CompanyFromResponseWrapper<{id: string, scenarioId: string}, Company>(psPlatformClient<{id: string, scenarioId: string}, Company>({
        method: 'put', url: '/company-scenario-assignment', data: {
            id: companyId,
            scenarioId
        }
    }));
}

export const assignUserToCompany = async (companyId: string, userId: string): Promise<Company> => {
    return await CompanyFromResponseWrapper(psPlatformClient({
        method: 'put', url: '/company-user-assignment', data: {
            id: companyId,
            userId
        }
    }));
}

export const assignUserToDemoCompany = async (userId: string, userToken: string): Promise<Company> => {
    return await CompanyFromResponseWrapper(psPlatformClient({
        method: 'put', url: '/company-user-assignment-demo', token: userToken, data: {
            userId,
        }
    }));
}

export const assignUserToDefaultCompany = async (userId: string, userToken: string): Promise<Company> => {
    return await CompanyFromResponseWrapper(psPlatformClient({
        method: 'put', url: '/company-user-default-assignment', token: userToken, data: {
            userId,
        }
    }));
}

export const assignUserToCompanyByDomain = async (domain: string, userId: string, userToken: string): Promise<Company[]> => {
    return await CompanyFromResponseWrapper(psPlatformClient({
        method: 'put', url: '/company-user-assignment-by-domain', token: userToken, data: {
            userId,
            domain,
        }
    }));
}

const CompanyFromResponseWrapper = async <Req, Res>(request: Promise<AxiosResponse<Res, Req>>): Promise<Res> => {
    try {
        const result = await request;
        return result.data;
    } catch (err) {
        if (err instanceof AxiosError && err.response && err.response.status >= 400) {
            if (err.response.data && typeof err.response.data === 'object') {
                const errResponseDescription = err.response.data?.description;
                if (errResponseDescription?.reason === 'not_found') {
                    if (errResponseDescription?.resource === 'id') {
                        throw new Error("No companyId defined");
                    }
                    if (errResponseDescription?.resource === 'scenarioId') {
                        throw new Error("No scenarioId defined");
                    }
                    if (errResponseDescription?.resource === 'userId') {
                        throw new Error("No userId defined");
                    }
                    if(errResponseDescription?.resource === 'Company with domain'){
                        throw new Error("No company with specified domain exists");
                    }
                } else if (errResponseDescription?.reason) {
                    throw new Error(errResponseDescription?.reason);
                }
            }
        }
        throw err;
    }
}

export async function getAmCompanyConfig<T extends ConfigEntry>(companyId: string, configType: string): Promise<CompanyConfig<T>> {
  const result = await psPlatformClient<null, CompanyConfig<T>>({
    method: 'get',
    url: `/am/${companyId}/company/config/${configType}`,
  });
  return result.data;
}

export async function saveAmCompanyConfig<T extends ConfigEntry>(companyId: string, configType: string, config: CompanyConfig<T>): Promise<CompanyConfig<T>> {
  const result = await psPlatformClient<CompanyConfig<T>, CompanyConfig<T>>({
    method: 'post',
    url: `/am/${companyId}/company/config/${configType}`,
    data: config,
  });
  return result.data;
}

export async function getAmCompanyModels(companyId: string): Promise<AMCompanyModel[]> {
    const result = await psPlatformClient<null, AMCompanyModel[]>({
        method: 'get',
        url: `/am/${companyId}/company/model`,
    });
    return result.data;
}

export async function saveAmCompanyModel(companyId: string, modelMeta: Pick<AMCompanyModel, 'title' | 'description' | 'type'>, file: File, updateFileTransferProgress?: (percentCompleted: number)=>void): Promise<AMCompanyModel> {
    const formDataInstance = new FormData();

    formDataInstance.append("file", file);
    formDataInstance.append("title", modelMeta.title);
    formDataInstance.append("type", modelMeta.type);
    if(modelMeta.description){
        formDataInstance.append("description", modelMeta.description);
    }

    return await AMCompanyModelResponseWrapper<FormData, AMCompanyModel>(psPlatformClient<FormData, AMCompanyModel>({
        method: 'post',
        url: `/am/${companyId}/company/model`,
        headers: {
            'Content-Type': `multipart/form-data;`,
        },
        data: formDataInstance,
        onUploadProgress: ((progressEvent) => {
            if(progressEvent?.total) {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent?.total)
                updateFileTransferProgress && updateFileTransferProgress(percentCompleted);
            }
        })
    }));
}

export const getAmCompanyModelDownloadUrl = async (companyId: string, modelId: string) => {
    return await AMCompanyModelResponseWrapper<null, {  url: string }>(psPlatformClient<null, {  url: string }>(
        {method: 'get', url: `/am/${companyId}/company/model/${modelId}/get-url`}))
}

export const getWhatIfScenarioConfig = async (companyId: string, configName: string) => {
  const config = await getAmCompanyConfig<WhatIfScenarioConfig>(companyId, ConfigType.WhatIfScenario);

  if (!config.configs[configName]) {
    throw new DomainError(`No What If Scenario Config ${configName} found for this company`);
  }

  return config.configs[configName];
}
export const saveTemplateConfig = async (companyId: string, config: ProjectTemplateConfig) => {
  const result = await psPlatformClient<{ companyId: string, config: ProjectTemplateConfig }, null>(
    {method: 'post', url: `/am/${companyId}/set-template-config`, data: {companyId, config}});
  return result.data;
}

const AMCompanyModelResponseWrapper = async <Req, Res>(request: Promise<AxiosResponse<Res, Req>>): Promise<Res> => {
    try {
        const result = await request;
        return result.data;
    } catch (err) {
        if (err instanceof AxiosError && err.response && err.response.status >= 400) {
            if (err.response.data && typeof err.response.data === 'object') {
                const errResponseDescription = err.response.data?.description;
                if (errResponseDescription?.reason) {
                    throw new Error(errResponseDescription?.reason);
                }
            }
        }
        throw err;
    }
}