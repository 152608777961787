import { useDashboardService } from "../../platform/assets-management/builder/DashboardConfigService";
import {
  useOnStoreReady,
  useUpdateOnGlobalContextChange
} from "../../platform/assets-management/builder/widgets/commons";
import {
  FilterProps,
  useLineItemFilter,
} from "../../platform/lineitems-store/MetadataFilters";
import {LineItemsStore, PersistenceQuery, StoreQuery} from "../../platform/ps-models/lineitems-store";
import { buildQueryFromFilters } from "../../platform/assets-management/scenarios/ScenarioFilter.component";
import { useEffect, useMemo, useState } from "react";
import { authStorage } from "../../platform/auth";
import {getAmProjectConfig, ZERO_TIME_INDEX} from "../../platform/ps-models";
import {clone} from "ramda";
import {FilterDetails} from "../../platform/ps-types";
import {LineItemsFieldSet, ValueType} from "../../platform/ps-models/line-items";
import {buildLineItemsStorePersistence} from "../../platform/lineitems-store/RestLineItemsStore.persistence";
import {usePSQuery} from "../../platform/lineitems-store/LineItemsStore.hook";
import {LoadingBlock} from "../../platform/ui/Loading";

let persistence = buildLineItemsStorePersistence();

export function ScenarioFilters(){
  let { getService } = useDashboardService();
  const dService = getService();
  useUpdateOnGlobalContextChange({
    appContext: dService,
    id: "ctxLoader.ScenarioFilters",
  });
  useOnStoreReady({appContext: dService, id: "ScenarioFilters"});
  const store = dService.getStore();
  if(dService.storeIsLoading()){
    return <LoadingBlock/>
  }
  return <ScenarioFiltersImpl store={store} />

}

function ScenarioFiltersImpl({store}: {store: LineItemsStore}){
  let { getService } = useDashboardService();
  const dService = getService();
  const filterConfig: Record<string, Partial<FilterProps> | string> = useMemo(()=>{
    return dService
    .getConfig()
    .getFilterConfig();
  }, [dService.getConfig().getFilters()])

  const initRequiredFiltersMap = useMemo(()=>{
    const init: Record<string, string[]> = {};
    for (let key in filterConfig) {
      let filterDef: string | Partial<FilterProps> = filterConfig[key];
      if(typeof filterDef !== "string"){
        if (filterDef.required) {
          const value = store?.getExecution()
              .getFieldValues(key)
          if (value && value.length) {
            init[key as string] = [value[0] as string];
          } else {
            console.warn("No value");
          }
        }
      }
    }
    return init;
  }, [filterConfig])

  const company = authStorage.getCompany();
  let { collection } = getAmProjectConfig(company);
  let query = dService.getConfig().getQuery();

  const initialSelection = useMemo(() => {
    return {
      ...initRequiredFiltersMap,
      ...dService.getGlobalContext()?.filterDetails?.filtersMap,
    }
  }, [dService.getGlobalContext()?.filterDetails?.filtersMap, initRequiredFiltersMap])
  const serializedQuery = query.serialize();

  const serializedQueryInstance = useMemo(()=>{
    let sqI = clone(query.serialize());
    sqI._havingParamValues = {};
    sqI._selectsFirstParamValues = [];
    sqI._selectParams = dService.getConfig().getFilters().filter((f)=>f.required && f.getFromSourceStore).map((f)=>f.fieldName);
    return sqI;
  }, [serializedQuery]) ;

  let allRequiredFiltersParamStore = usePSQuery(collection, PersistenceQuery.deserialize(serializedQueryInstance)
      .lineItemsMetadataWithSingleTimeSlot()
  );
  const serializedAllRequiredFiltersParamStore = useMemo(()=>{return allRequiredFiltersParamStore?.serialize()}, [allRequiredFiltersParamStore]) ;
  const serializedStore = useMemo(()=>{
    return store.serialize()}, [store])
  const optionsStore = useMemo(()=>{
    let optsStore = clone(store.view(StoreQuery.all()).clone());
    if(allRequiredFiltersParamStore){
      for(let liName in allRequiredFiltersParamStore.getDataSet().getLineItems()){
        let existingLiInOptsStore = optsStore.getDataSet().getLineItem(liName);
        let liWithAllRequiredFilterParams = allRequiredFiltersParamStore.getDataSet().getLineItem(liName).clone();
        if(existingLiInOptsStore){
          let tempLi = existingLiInOptsStore.clone();
          tempLi.setFields(LineItemsFieldSet.fromFieldMap({...tempLi.fields.getFieldMap(), ...liWithAllRequiredFilterParams.fields.getFieldMap()}));
          optsStore.getDataSet().addLineItem(tempLi);
        } else {
          optsStore.getDataSet().addLineItem(liWithAllRequiredFilterParams);
        }
      }
    }
    return optsStore;
  }, [serializedAllRequiredFiltersParamStore, serializedStore])

  const onFilterChange = (
      fQuery: StoreQuery,
      selectedFilters: Record<string, string[]>,
  ) => {
    console.info("On Filter Change", selectedFilters);

    // Global Context holds everything that we want to persist for runtime
    dService.updateGlobalContext({
      filterDetails: {
        filterQuery: buildQueryFromFilters(selectedFilters),
        filtersMap: selectedFilters,
        initialFiltersMap: dService.getGlobalContext()?.filterDetails?.initialFiltersMap,
      } as FilterDetails,
    });
  };

  let [, filterComponent] = useLineItemFilter(filterConfig, optionsStore
          , {
        onFilterChange,
        initialSelection,
      }
  );
  return <div>{filterComponent}</div>;
}