
import {
  canBeNumber,
  FinancialValueType, formatAsUTC, getEpochFromFormattedUTCString, NumericFinancialValueTypes,
  round,
  STORE_VALUE_DECIMALS_KEY,
  VALUE_TYPE_KEY,
  valueAsNumber
} from "./index";
import {LineItemRow} from "./lineitems-store";
import {ValueType} from "./line-items";

export function formatSingleCellRow(row: LineItemRow | undefined): string {
  if (row?.["0"]?.value === undefined) {
    return 'N/A'
  }
  if (row[VALUE_TYPE_KEY] === undefined) {
    return row["0"].value.toString()
  } else {
    return formatValueWithValueType(row["0"].value, row[VALUE_TYPE_KEY].value as FinancialValueType,
        row[STORE_VALUE_DECIMALS_KEY]?.value !==undefined ? parseInt(row[STORE_VALUE_DECIMALS_KEY]?.value as string) : undefined)
  }
}

export function getExcelFormattingCode(valueType: FinancialValueType, decimalPlacesInValue?: number): string | null {
  if(![...NumericFinancialValueTypes].includes(valueType as any)){
    return null;
  }
  switch (valueType.toLowerCase()) {
    case "dollars":
    case "australiandollars":
    case "rupee":
    {
      let symbol = "₹"
      if(valueType.toLowerCase() === "dollars"){
        symbol = "$"
      } else if(valueType.toLowerCase() === "australiandollars"){
        symbol = "$" // Ideally should be A$
      }
      return getExcelFormatCodeForNumber({prefix: symbol}, decimalPlacesInValue)
    }
    case "dollarx1000":
    case "dollarthousand":
    case "dollarmillion": {
      return getExcelFormatCodeForNumber({prefix:"$"}, decimalPlacesInValue)
    }
    case "percentx100":
    case "percentage": case "percent":
      return getExcelFormatCodeForNumber({suffix:"%"}, decimalPlacesInValue)
    case "multipleof":
    case "mw":
    case "zip":
    case "date":
    case "utc_mmddyyyy":
    case "utc_mmyyyy":
    case "utc_mmm-yy":
    case "utc_dd-mmm-yy":
    case "utc_ddmmyy":
    case "kwp":
    case "kwac":
    case "kw":
    case "kwh":
    case "kwh/kwp/yr":
    case "c/kwh":
    case "number":
      return null;
    default:
      return null;
  }
}

export function formatValueWithValueType(value: ValueType, valueType: FinancialValueType, decimalPlacesInValue?: number): string {

  let originalValue = value;

  if(valueType === "string" || value === null || value === undefined || typeof value === "string") {
    return value?.toString() || "";
  }

  value = value as number;

  switch (valueType.toLowerCase()) {
    case "zip":
      return value.toString();
    case "date":
      // @ts-ignore
      let date = new Date(value);
      return date.toLocaleDateString();
    case "utc_mmddyyyy":
      return formatAsUTC(new Date(typeof originalValue === "string" ? (originalValue || 0): value), 'MM/dd/yyyy')
    case "utc_mmyyyy":
      return formatAsUTC(new Date(typeof originalValue === "string" ? (originalValue || 0): value), 'MM/yyyy')
    case "utc_mmm-yy":
      return formatAsUTC(new Date(typeof originalValue === "string" ? (originalValue || 0): value), 'MMM-yy')
    case "utc_dd-mmm-yy":
      return formatAsUTC(new Date(typeof originalValue === "string" ? (originalValue || 0): value), 'dd-MMM-yy')
    case "utc_ddmmyy":
      return formatAsUTC(new Date(typeof originalValue === "string" ? (originalValue || 0): value), 'dd/MM/yy')
    case "dollars":
    case "australiandollars":
    case "rupee":
    {
      let sign = ""
      if (value < 0) {
        value = -value
        sign = "-"
      }
      let symbol = "₹"
          if(valueType.toLowerCase() === "dollars"){
            symbol = "$"
          } else if(valueType.toLowerCase() === "australiandollars"){
            symbol = "$" // Ideally should be A$
          }

      return `${sign}${symbol}${formatNumber(value, decimalPlacesInValue)}`
    }
    case "dollarx1000": {
      let sign = ""
      if (value < 0) {
        value = -value
        sign = "-"
      }
      return `${sign}$${formatNumber(value*1000, decimalPlacesInValue)}`
    }
    case "dollarthousand": {
      let sign = ""
      if (value < 0) {
        value = -value
        sign = "-"
      }
      return `${sign}$${formatNumber(value/1000, decimalPlacesInValue)}K`
    }
    case "dollarmillion": {
      let sign = ""
      if (value < 0) {
        value = -value
        sign = "-"
      }
      return `${sign}$${formatNumber(value/1000000, decimalPlacesInValue)}M`
    }
    case "percentx100":
      // @TODO: WRAP THIS
      return `${formatNumber(value * 1000 * 100 / 1000, decimalPlacesInValue)}%`
    case "percentage": case "percent":
      return `${formatNumber(value, decimalPlacesInValue)}%`
    case "multipleof":
      return `${formatNumber(value, decimalPlacesInValue)} x`
    case "mw":
      return `${formatNumber(value, decimalPlacesInValue)} MW`
    case "kwp":
    case "kwac":
    case "kw":
      return `${formatNumber(value, decimalPlacesInValue)} KW`
    case "kwh":
    case "kwh/kwp/yr":
    case "c/kwh":
    case "number":
      return formatNumber(value, decimalPlacesInValue).toString()
    default:
      return formatNumber(value, decimalPlacesInValue).toString()
  }
}

export function formattedValueAsNumber(value: string, valueType: FinancialValueType): number {
  //Set default value type

  switch (valueType.toLowerCase()) {
    case "zip":
      return valueAsNumber(value)
    case "dollars":
      return valueAsNumber(value.replace('$', ''));
    case "dollarx1000": {
      return valueAsNumber(value.replace('$', ''))/1000;
    }
    case "dollarthousand":
      return valueAsNumber(value.replace('$', '').replace('K', ''))*1000;
    case "dollarmillion":
      return valueAsNumber(value.replace('$', '').replace('M', ''))*1000000;
    case "percentx100":
      return valueAsNumber(value.replace('%', ''))/100;
    case "percentage": case "percent":
      return valueAsNumber(value.replace('%', ''));
    case "multipleof":
      return valueAsNumber(value.replace(' x', ''));
    case "mw":
      return valueAsNumber(value.replace(' MW', ''));
    case "kwp":
    case "kwac":
    case "kw":
      return valueAsNumber(value.replace(' KW', ''));
    case "kwh":
    case "kwh/kwp/yr":
    case "c/kwh":
    case "$/mwp.a.":
    case "number":
      return valueAsNumber(value);
    case "utc_mmddyyyy":
      return getEpochFromFormattedUTCString(value, 'MM/dd/yyyy')
    case "utc_mmyyyy":
      return getEpochFromFormattedUTCString(value,'MM/yyyy')
    case "utc_mmm-yy":
      return getEpochFromFormattedUTCString(value, 'MMM-yy')
    case "utc_dd-mmm-yy":
      return getEpochFromFormattedUTCString(value, 'dd-MMM-yy')
    case "utc_ddmmyy":
      return getEpochFromFormattedUTCString(value, 'dd/MM/yy')
    default:
      return valueAsNumber(value);
  }
}


const numberFormatter_m1 = new Intl.NumberFormat('en-US', {});

const numberFormatter0 = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const numberFormatter1 = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});

const numberFormatter2 = new Intl.NumberFormat('en-US', {
  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});

const numberFormatter3 = new Intl.NumberFormat('en-US', {
  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 3, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 3, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
});


const numberFormatterDefault = new Intl.NumberFormat('en-US', {
  // These options are needed to round to whole numbers if that's what you want.
  maximumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
});



function formatNumber(value: number, decimalPlaces?:number) {

   switch (decimalPlaces) {
     case -1:
       return numberFormatter_m1.format(value);
     case 0:
       return numberFormatter0.format(value<0 ? Math.ceil(value) : Math.floor(value));
     case 1:
       return numberFormatter1.format(value);
     case 2:
       return numberFormatter2.format(value);
     case 3:
       return numberFormatter3.format(value);
     default:
       return  numberFormatterDefault.format(value);
   }
}

function getExcelFormatCodeForNumber(symbols:{prefix?:string, suffix?:string}, decimalPlaces?:number){
  let decimalSignifier = '';
  if(decimalPlaces === undefined){
    decimalPlaces = 2;
  } else if(decimalPlaces === -1){
    decimalPlaces = 6;
  }

  if (decimalPlaces > 0) {
    decimalSignifier = '.' + '#'.repeat(decimalPlaces);
  }

  return `_("${symbols?.prefix ?? ''}"* #,##0${decimalSignifier}${symbols?.suffix ?? ''}_);_("${symbols?.prefix ?? ''}"* (#,##0${decimalSignifier}${symbols?.suffix ?? ''});_("${symbols?.prefix ?? ''}"* "-${symbols?.suffix ?? ''}"_);_(@_)`;
}

function addComaToThousands(num: number) {
  const parts = num.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function inferValueType(text: any, value?: any): FinancialValueType {

  if(typeof text === "number") {
    return "number";
  }

  if(text instanceof Date) {
    return "date";
  }

  if(text === null || text === undefined) {
    return "string";
  }

  if(typeof text !== "string") {
    text = "string"
  }

  let valueType : FinancialValueType= "number";

  if (text.indexOf("%") > -1) {
    valueType = "percentX100";
  } else if (text.indexOf("$") > -1) {
    valueType = "dollars";
  } else if (text.startsWith("A$")) {
    valueType = "australianDollars";
  } else if(text.endsWith(" x")){
    valueType = "multipleOf";
  } else  if(canBeNumber(text)) {
    valueType = "number";
  } else {
    valueType = "string";
  }

  if(value!==undefined && typeof value === "number" && valueType === "string"){
    valueType = "number";
  }

  return valueType;
}