import {firestore} from "../../firebase/firebase.core";
import firebase from "firebase";
import {PsUser} from "./PsUser";
import {MissingUserEmailError} from "./errors";
import {fsGetLinkedUserData} from "../../redux/user/user.firebase";
import {UserData, SignUpUserData} from "../ps-models/user";
import psPlatformClient from "../psPlatformClient";
import {OnboardingData, PrimaryIntakeData} from "../ps-types";

let psUserCache: Record<string, UserData> = {}
export async function accessPsUser(user: firebase.User, token: string): Promise<PsUser> {
    if (!user.email || !user.email.trim()) {
        throw new MissingUserEmailError("Check your email inbox to verify your account")
    }
    if (!psUserCache[user.uid]) {
        psUserCache[user.uid] = await fetchCompleteUserData(token)
    } else {
        console.info('Most likely we dont need this cache, the item was served from the cache');
    }
    return new PsUser(user, token, psUserCache[user.uid])
}

export function clearPsUserCache() {
    psUserCache = {}
}

async function fetchCompleteUserData(userToken: string): Promise<UserData> {
  const result = await psPlatformClient<void, UserData>({ method: 'get', url: `/user-data`, token: userToken});
  return result.data
}

export async function updateUserOnboardingData(newUserData: {onboardingData: OnboardingData}) {
  await psPlatformClient<{newUserData: {onboardingData: OnboardingData}}>({ method: 'put', url: `/user-data`, data: {newUserData}});
}

export async function updateUserIntakeData(newUserData: {intake: PrimaryIntakeData}) {
    await psPlatformClient<{newUserData: {intake: PrimaryIntakeData}}>({ method: 'put', url: `/user-data`, data: {newUserData}});
}

const USERS_COLLECTION = "users"

export async function createUserDocumentIfNotExists(
    user: firebase.User,
): Promise<void> {
    const userRef = firestore.doc(`${USERS_COLLECTION}/${user.uid}`);
    const snapShot = await userRef.get();
    if (!snapShot.exists) {
        const now = new Date();
        const userData = {
            email: user.email,
            createdAt: now,
            lastModified: now,
            id: user.uid,
        }
        await userRef.set(userData)
    }
}

export async function setUserProfile(
    user: firebase.User,
    signUpData: SignUpUserData
): Promise<void> {
    const userRef = firestore.doc(`${USERS_COLLECTION}/${user.uid}`);

    let userProfileObjectFlattened = {
        firstName: signUpData.profile.firstName,
        lastName: signUpData.profile.lastName,
        displayName: signUpData.profile.displayName,
        ...(signUpData.profile?.userProvidedCompanyName ? {userProvidedCompanyName: signUpData.profile?.userProvidedCompanyName} :{}),
        ...(signUpData.profile?.campaign ? {campaign: signUpData.profile?.campaign} :{}),
    }
    const snapShot = await userRef.get();
    if (snapShot.exists) {
            await userRef.update({
                ...signUpData,
                ...userProfileObjectFlattened
            })
    } else {
        const now = new Date();
        const userData = {
            email: user.email,
            createdAt: now,
            lastModified: now,
            id: user.uid,
            ...signUpData,
            ...userProfileObjectFlattened,
        }
        await userRef.set(userData);
    }
}

export async function addLinkedDataToCurrentUser(user: PsUser): Promise<void> {
    const data = await fsGetLinkedUserData() // They do this concurrently in the old code
    user.setLinkedUserData(data)
}

export async function checkUserExists(email: string) {
    try {

        // Check params before proceeding
        if (!email) new Error("No email defined");

        // Check to see if user exists
        const userArray = await firestore.collection(USERS_COLLECTION).where("email", "==", email).get();
        if (userArray.empty) {
            return ({
                exists: false
            });
        } else {
            return ({
                exists: true,
                id: userArray.docs[0].id
            });
        }
    } catch (error) {
        console.error('Error checking if user exists: ', error);
    }
    return {exists: false}
}

