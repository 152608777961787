import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {auth} from "../../firebase/firebase.core";
import {Button, Container, Divider, Header, Icon, Image} from "semantic-ui-react";
import { format } from "date-fns";
import {checkIsUserEmailVerified, sendVerificationEmail} from "./auth.client";
import {useTimer} from "../ui/UseTimer";
import {SITE_URL} from "../core";
import {useAuthCtx} from "./AuthContextProvider";
import ErrorRenderer from "./ErrorRenderer";
import {authStorage} from "./AuthStorage";

const MAX_CHECK_MINUTES = 10; // Stop checks after N minutes
const CHECK_INTERVAL_MS = 5000;
const MAX_CHECKS = Math.ceil(60*MAX_CHECK_MINUTES*1000/CHECK_INTERVAL_MS);

export function NewVerifyEmailPage() {
    const currentUser = authStorage.getUser();
    const [lastChecked, setLastChecked] = useState(() => new Date())
    const [timerExpired, resetTimer] = useTimer(0)
    const [error, setError] = useState<any| null>(null)
    const {reloadAuth, logout} = useAuthCtx()
    const emailSent = false
    const [timerIds, setTimerIds] = useState<NodeJS.Timeout[]>([]);

    const reloadPage = () => {
        let timerId = setTimeout(() => reloadAuth(), 2000);
        setTimerIds((prev)=>{
            return [...prev, timerId];
        })
    }

    const onResendVerificationEmail = async () => {
        try {
            resetTimer(5000)
            await sendVerificationEmail()
        } catch (e) {
            setError(e)
            console.error("Failed to send email for user verification", e);
            reloadPage();
        }
    }

    useEffect(() => {
        let numChecked = 0
        async function checkUserVerification() {
            try {
                setLastChecked(new Date())
                if (await checkIsUserEmailVerified()) {
                  reloadPage();
                  return;
                }

                numChecked++;
                if (numChecked >= MAX_CHECKS) {
                  console.log("Checking user reload stopped due to inactivity");
                } else {
                    let timerId = setTimeout(checkUserVerification, CHECK_INTERVAL_MS);
                    setTimerIds((prev)=>{
                        return [...prev, timerId];
                    })
                }
            } catch (e) {
                setError(e)
                console.error("Failed to check user verification", e)
                reloadPage();
            }
        }
        checkUserVerification().catch(e => console.error(e))
        return () => {
            // @TODO: We need to move this to some other place, because timerIds are state variables and are not available during the first render
            for(let tId of timerIds){
                clearTimeout(tId);
            }
        }
    }, [])

    return <Container>
        <a href={SITE_URL}>
          <Header as='h1' inverted>
            <Image src={"/logos/Perl-Logo-Icon-Black-2x.png"} style={{ margin: 0, height: 'auto', width: 'auto', maxWidth: '125px' }} />
          </Header>
        </a>
        <Divider hidden />
        <Container text>
          <Header as="h1" className="hero-title">Please verify your email</Header>
          <p>Hey {currentUser?.data?.firstName}, you're almost there! We sent an email to <strong>{currentUser?.data?.email}</strong></p>
          <p>This could take up to 5 minutes. Simply click the link in the email to complete your signup.</p>
          <p>If you don't see it, make sure to also check your spam folder.</p>
          <strong><p>If you have already verified your email, please refresh this page <span role="img" aria-label="smiley face">😊</span></p></strong>
          { !!lastChecked && <p><i>Last checked at {format(lastChecked, "MMMM dd yyyy, h:mm a")}</i></p> }
          <Divider hidden />
          <Button type="submit" className="raised" size='huge' loading={!timerExpired} disabled={!timerExpired} onClick={onResendVerificationEmail}>
            {emailSent ? "Resend Verification Email" : "Resend Verification Email"}
            <Icon name='refresh' className="slightLeftMargin"/>
          </Button>
          <Button type="submit" className="raised" size='huge' loading={!timerExpired} disabled={!timerExpired} onClick={logout}>
              Logout
            <Icon name='sign out' className="slightLeftMargin"/>
          </Button>
          <Divider hidden />
          <p>Having trouble? <Link to="/contact">Contact us</Link></p>
          <ErrorRenderer error={error} />
        </Container>
      </Container>
}
