import "../Platform.css";
import DbActionsDashboard from "./DbActionsDashboard";
import {Company} from "../ps-types";
import {fetchNonCompaniesCollections} from "./admin.client";
import {getCompanies} from "../company/company.client";
import {useEffect, useState} from "react";
import {Segment} from "semantic-ui-react";

const InitializationManagement = () => {
    const [nonCompanyCollections, setNonCompanyCollections] = useState<any[]>([]);
    const [companyCollections, setcompanyCollections] = useState<any[]>([]);


    useEffect(() => {
        fetchNonCompaniesCollections()
            .then((data) => {
                const nonCompanyCollections = Object.values(data);
                const collections = nonCompanyCollections.map((collection: any, index: number) => {
                    return {
                        id: collection.name,
                        label: collection.name,
                        name: collection.name,
                    };
                });

                // @ts-ignore
                setNonCompanyCollections(collections);
            })
            .catch((error) => {
                console.error('Error fetching non company collections:', error);
            });

        getCompanies()
            .then((companies) => {
                    // format the data to be used in the DbActionsDashboard
                    const companyCollections = companies
                        .filter((company: { id: string, name?: string }) => company.name !== 'Acme INC')
                        .map((company, index) => {
                            let collectionName = '';
                            if ((company as Company).amProjectConfig) {
                                collectionName = (company as Company).amProjectConfig?.collection ?? '';
                            } else {
                                collectionName = company.id;
                            }
                            return {
                                id: index,
                                label: company.name,
                                name: collectionName,
                            }
                        });

                    setcompanyCollections(companyCollections);
                }
            )
            .catch((error) => {
                console.error('Error fetching company collections:', error);
            });
    }, []);

    return <>
        <Segment>
            <DbActionsDashboard title={'Companies Section'} selectPlaceHolder={'Select Company'}
                                collections={companyCollections}/>
        </Segment>
        <Segment>
            <DbActionsDashboard title={'Collections Section'} selectPlaceHolder={'Select Collection'}
                                collections={nonCompanyCollections}/>
        </Segment>
    </>
}

export default InitializationManagement;