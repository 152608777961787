import {ModelConfigMapperResult} from "../../ps-models/exa";
import React, {useEffect, useState} from "react";
import {LineItemsStore, StoreQuery} from "../../ps-models/lineitems-store";
import {Message, Segment, Table} from "semantic-ui-react";
import {LineItemsTableWithFormulas} from "../../lineitems-store/LineItemsTableView";

export function ExcelMappingResultsView({result, store}: { result: ModelConfigMapperResult, store?: LineItemsStore }) {


  return <Segment>
    {result && result.errors.length > 0 && result.errors.map((error, index) => <>
      <Message key={index} negative>
        <Message.Header>Error</Message.Header>
        <p>{error}</p>
      </Message>
    </>)}

    {result && result.extractionResults.map(data => <>
      {data.extractedData && data.extractedData.errors.length > 0
        && data.extractedData.errors.map((error, index) => <>
          <h3>Excel Reading Errors</h3>
          <Message key={index} negative>
            <Message.Header>Error</Message.Header>
            <p>{error}</p>
          </Message>

        </>)}

      {data.extractedData && Object.entries(data.extractedData.values).map(([key, value]) => {
        return <div key={key} style={{marginTop: "20px", width: "100%", overflow: "auto"}}>
          <h3>{key}</h3>
          <Table celled size="small">
            <Table.Body>
              {value.value.map((row, index) => {
                return <Table.Row key={index}>
                  {row.map((cell, index) => {
                    return <Table.Cell key={index}>{cell.text}</Table.Cell>
                  })}
                </Table.Row>
              })}
            </Table.Body>
          </Table>
        </div>
      })}

      {data.lineItems && store && <><h3>Line Items</h3>
          <LineItemsTableWithFormulas
              store={store}
              queryResult={store.query(
                StoreQuery.byNames(
                  data.lineItems.map(lineItem => lineItem.name)
                )
              )}
          /></>
      }

    </>)}
  </Segment>
}