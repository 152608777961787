import * as React from "react";
import "./Platform.css";
import {useDispatch, useSelector} from "react-redux";
import { selectCompaniesData } from '../redux/admin/admin.selectors';
import { getCompanies } from "../redux/admin/admin.actions";
import {Button, Dropdown} from "semantic-ui-react";
import {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {PS_URLS, useCompanyId} from "./core";
import {Company} from "./ps-types";
import { authStorage } from "./auth";
import {getCompanyHome} from "./assets-management/config";
import {getAmProjectConfig} from "./ps-models";


export function CompanySelectorForAdmins() {
  const history = useHistory();
  const currentUser = authStorage.getUser();
  const currentCompanyId = useCompanyId()
  const companies = useSelector(selectCompaniesData) as Company[];
  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(getCompanies());
  }, [dispatch]);
  return <>
    <Dropdown
        placeholder='Select Company'
        selection
        search={true}
        deburr
        loading={companies.length===0}
        value={currentCompanyId}
        options={companies.filter((company: {id:string, name?: string})=> company.name !== 'Acme INC')
            .map((company: Company) => ({
                  text:company.name || `UNDEFINED: ${company.id}`,
                  value:company.id,
                  key:company.id,
                  selected: company.id === currentCompanyId,
                  onClick:()=>{
                    authStorage.setCompany(company)
                    const {namespace} = getAmProjectConfig(company);
                    let amCompanyHome = getCompanyHome(company.id,
                        namespace)
                    history.push(amCompanyHome)
                  }}
            ))}
    />
    {currentCompanyId && currentUser.company?.id !== currentCompanyId && <Button
        onClick={()=>{
          authStorage.setDefaultCompany()
          history.push(PS_URLS(authStorage.getCompany().id).assetsManagement())
        }}
    >Go back to {currentUser.company?.name}</Button>}
  </>
}