import {useHistory} from "react-router-dom";
import {useCompanyId, usePlatformUrls} from "./core";
import {Button} from "semantic-ui-react";
import * as React from "react";
import {getCompanyHome} from "./assets-management/config";
import {authStorage} from "./auth";

export function MainMenu() {
  const isAdmin = authStorage.getUser().isAdmin();
    const history = useHistory();
    let companyId = useCompanyId();
    let {assetsManagement, psAdminDashboard} = usePlatformUrls();
    let redirectionUrl = '';
    if (!companyId) {
        const company = authStorage.getCompany();
      redirectionUrl = getCompanyHome(company.id,
            company.amProjectId ?? 'DEMO');
    }

    //Todo: Hacky code, need to fix this
    return <div>
        <Button onClick={() => history.push(redirectionUrl || assetsManagement())}>Asset Management</Button>
      {isAdmin && <Button onClick={() => history.push(psAdminDashboard())}>PS Admin Dashboard</Button>}
    </div>
}