import React, {useEffect, useState} from "react";
import {Button, Select} from "semantic-ui-react";
import {DashboardConfigService, useDashboardService} from "./DashboardConfigService";
import {useScenarioSelect} from "../scenarios/scnearioSelect";
import {LineItemsStore, StoreQuery} from "../../ps-models/lineitems-store";
import {FilterProps, useLineItemFilter} from "../../lineitems-store/MetadataFilters";
import {getLastDateOfMonthQtrOrYearInUTC, TimeUnits} from "../../ps-models";
import {useGranularUTCDateSelector, useUTCDateSelector} from "../../lineitems-store/DateRange";
import {ParameterLineItem} from "../../ps-models/line-items";
import {PreDefinedDateRangeKindType} from "../../ps-types";
import {useOnStoreLoading, useOnStoreReady, useUpdateOnGlobalContextChange} from "./widgets/commons";
import {LoadingBlock} from "../../ui/Loading";
import {keys} from "ramda";
import DatePicker from "react-datepicker";
import { ScenarioFilter } from "../scenarios/ScenarioFilter.component";
import {buildUtcDateRangeForDateRangeKind} from "../../ps-models/PreDefinedDateRanges.model";

export function DashboardPageStoreCtxLoader({render}: {render:((service: DashboardConfigService)=>React.ReactNode)}){
  let {getService} = useDashboardService();
  return <div style={{marginBottom: "200px"}}>
    <DashboardLoader />
    <TopLevelComponents  />
    <>{render(getService())}</>
  </div>
}

function DashboardLoader() {
  let {getService} = useDashboardService();
  const dService = getService();
  useOnStoreReady({appContext: getService(), id: "dashboardLoader"});
  useOnStoreLoading({appContext: getService(), id: "dashboardLoader"});
  return <>
    {dService.storeIsLoading() && <LoadingBlock /> }
  </>
}


function TopLevelComponents(){
  let {getService} = useDashboardService();

  const dService = getService();
  const store = dService.getStore()
  useOnStoreReady({appContext: dService, id: "ctxLoader.TopLevelComponents"});


  const utcDateSelectorConfig = dService.getConfig().getUTCDateSelector();



  return <>

    {(!dService.constraints?.viewDateRange && dService.getConfig().isGlobalRangeSelectorExposed()) && <GlobalRangeSelection />}
    <div style={{marginTop: '20px'}}>
      {(dService.getConfig().isProjectVersionSelectorExposed() || dService.getConfig().getFilters().length >0) && <ScenarioFilter/>}
    </div>
    {utcDateSelectorConfig &&  (utcDateSelectorConfig.withGranularitySelector ?
      <GranularUTCDateRangeSelector store={store} rangeSelection={utcDateSelectorConfig?.rangeSelection} /> :
      <UTCDateRangeSelector store={store} rangeSelection={utcDateSelectorConfig?.rangeSelection} />)}

  </>
}

// @TODO: Need to rename and check what's the difference buildUTCDate
function reactDatePickerLocalDateToUTC(givenDate: Date){
  return new Date(givenDate.getUTCFullYear(),
      givenDate.getUTCMonth(),
      givenDate.getUTCDate(),
      givenDate.getUTCHours(),
      givenDate.getUTCMinutes(),
      givenDate.getUTCSeconds());
}

function GlobalRangeSelection() {
  let {getService} = useDashboardService();
  let dashboardService = getService();
  const {defaultGranularity, availableGranularities,
      autoAlignmentOfStartDateDisabled,
      defaultSelectedDateRangeKind
  } = dashboardService.getConfig().getGlobalRangeSelectorConfig();
  let {from: start, to: end} = dashboardService.getCompleteDateRangeOfRenderedStores();

  const [startDate, setStartDate] = useState<Date>(start);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [maxSelectableDate, setMaxSelectableDate] = useState<Date>(end);
  const [granularity, setGranularity] = useState<TimeUnits>(defaultGranularity);

  useEffect(()=>{
    let endDatePerDefaultSlotSize = dashboardService.getEndDateUsingDefaultTimeSlotSize(start, granularity);
    let initialEndDate = endDatePerDefaultSlotSize > end ? end : endDatePerDefaultSlotSize;
    const alignEndDate = autoAlignmentOfStartDateDisabled ? (!endDate) : true;
    if(alignEndDate){
      setEndDate(initialEndDate);
    }
  },[end, granularity, autoAlignmentOfStartDateDisabled])


  useEffect(()=>{
    let maxDatePerDefaultSlotSize = dashboardService.getEndDateUsingDefaultTimeSlotSize(startDate, granularity);
    let maxDate = maxDatePerDefaultSlotSize > end ? end : maxDatePerDefaultSlotSize;
    setMaxSelectableDate(maxDate);
  }, [startDate, granularity])

  useEffect(()=>{
    if(dashboardService?.getGlobalContext()?.granularity !== defaultGranularity){
      dashboardService.updateGlobalContext({granularity: defaultGranularity});
    }
  }, [defaultGranularity])

  useEffect(()=>{
    if(!autoAlignmentOfStartDateDisabled && startDate !== start){
      setStartDate(start);
    }
  }, [start, autoAlignmentOfStartDateDisabled])

  useEffect(()=>{ // @TODO: This will cause an additional re-render, simplify this.
    if(defaultSelectedDateRangeKind){
      handleDefaultDateRangeApplication(defaultSelectedDateRangeKind);
    }
  }, [])

  const handleChange = (dates: Date[]) => {
    let [start, end] = dates;
    setStartDate(start);
    if(end && (granularity === "months" || granularity === "quarters" || granularity === "years")) {
      end = getLastDateOfMonthQtrOrYearInUTC(end, granularity);
    }
    setEndDate(end);
  };

  let granularityOptions = [
    {key: 'months', text: 'Month', value: 'months'},
    {key: 'quarters', text: 'Quarter', value: 'quarters'},
    {key: 'years', text: 'Year', value: 'years'},
  ].filter(({value})=>(availableGranularities as string[]).includes(value));

  const handleApply = () => {
    if(startDate === null || endDate === null){
      return;
    }
    dashboardService.updateDateRange({from: startDate, to: endDate}, granularity);
  }

  const handleDefaultDateRangeApplication = (defaultSelectedDateRangeKind: PreDefinedDateRangeKindType) => {
    const defaultSelectedDateRange = buildUtcDateRangeForDateRangeKind(defaultSelectedDateRangeKind)
    setEndDate(defaultSelectedDateRange.to);
    setStartDate(defaultSelectedDateRange.from);
    let {from, to} = defaultSelectedDateRange;
    dashboardService.updateDateRange({from, to}, granularity);
  }

  let options: any = {
    selectsRange: true
  };

  if(granularity === 'years') {
    options = {
      ...options,
      dateFormat: "yyyy",
      showYearPicker: true
    }
  } else if(granularity === 'quarters') {
    options = {
      ...options,
      dateFormat: "yyyy Q",
      showYearDropdown: true,
      showQuarterYearPicker: true
    }
  } else if (granularity === 'months') {
    options = {
      ...options,
      dateFormat: "yyyy MMM",
      showMonthYearPicker: true,
      showYearDropdown: true,
      showMonthDropdown: true
    }
  }

  return <div style={{width: "500px", height: "22px", margin: "auto" }}>
    <div style={{marginRight: '10px', float: 'left'}}>
      <Select options={granularityOptions}
              value={granularity}
              onChange={(e, data) => {
                setGranularity(data.value as TimeUnits);
              }}
              placeholder="Select Granularity" />
    </div>
    <div style={{marginRight: '10px', float: 'left'}}>
      <DatePicker
        selected={reactDatePickerLocalDateToUTC(startDate)}
        minDate={reactDatePickerLocalDateToUTC(start)}
        maxDate={reactDatePickerLocalDateToUTC(maxSelectableDate)}
        onChange={handleChange}
        startDate={reactDatePickerLocalDateToUTC(startDate)}
        endDate={endDate ? reactDatePickerLocalDateToUTC(endDate): endDate}
        {...options}
      />
    </div>
    <div style={{marginRight: '10px', float: 'left'}}>
      <Button  content="Apply" disabled={startDate === null || endDate === null} onClick={handleApply} />
    </div>

  </div>

}



function GranularUTCDateRangeSelector({store, rangeSelection}: {store:LineItemsStore, rangeSelection?: boolean}){
  let {getService, ready} = useDashboardService();
  let [selectorComponent, dateRange] = useGranularUTCDateSelector(store, rangeSelection ?? false);

  useEffect(()=>{
    getService().updateGlobalContext({ utcDateRange: dateRange });
  }, [dateRange])

  return <div style={{marginTop: '20px'}}>
    {selectorComponent}
  </div>
}

function UTCDateRangeSelector({store, rangeSelection}: {store:LineItemsStore, rangeSelection?: boolean}){
  let {getService} = useDashboardService();
  let [selectorComponent, dateRange] = useUTCDateSelector(store.timeIndex.getUnit(), store, rangeSelection ?? false);

  useEffect(()=>{
    getService().updateGlobalContext({ utcDateRange: dateRange });
  }, [dateRange])

  return <div style={{marginTop: '20px'}}>
    {selectorComponent}
  </div>
}
