import {DashboardConfig, DashboardFilterConfig} from "./DashboardConfig";
import React, {useEffect, useState} from "react";
import {indexBy, mapObjIndexed, values} from "ramda";
import {Button, Checkbox, Icon, Input, Segment, Table} from "semantic-ui-react";
import {LineItemField} from "../../ps-types";
import {useDashboardService} from "./DashboardConfigService";

export function FilterEditor() {

  let [filters, setFilters] = useState<Record<string, DashboardFilterConfig>>({});
  let {getService, ready} = useDashboardService();
  const boardConfig = getService().getConfig()
  useEffect(() => {
    setFilters(indexBy(c => c.fieldName, boardConfig.getFilters()));
  }, []);

  const handleUpdate = (updatedFilters: Record<string, DashboardFilterConfig>) => {
    setFilters(updatedFilters);
  }

  const handleApply = () => {
    boardConfig.setFilters(values(filters));
    ready();
  }

  return <>
    <MultiParameterEditor
      params={filters}
      onUpdate={handleUpdate}
      />
    <Button primary
            onClick={handleApply}
    >Apply</Button>
  </>
}




interface Props {
  params: Record<string, DashboardFilterConfig>;
  onUpdate: (updatedData: Record<string, DashboardFilterConfig>, deleted: string[]) => void;
}

export const MultiParameterEditor: React.FC<Props> = ({ params, onUpdate }) => {
  const [messages, setMessages] = useState<Record<string, any>>({});
  const [deleted, setDeleted] = useState<string[]>([]);

  let fields = values(params);

  const handleUpdate = (key: number, field: keyof DashboardFilterConfig, value: string | boolean) => {
    const updatedData = fields.map((item, index) => {
      if (index === key) {
        return { ...item, [field]: value };
      }
      return item;
    });

    onUpdate(indexBy(f=> f.fieldName, updatedData), deleted);
  };

  const handleAdd = () => {
    onUpdate(indexBy(f=> f.fieldName,
      fields.concat([{fieldName: '', fiterLabel: '', getFromSourceStore: false, formattedOptions: false, required: false, singleSelect: false}])
    ), deleted);
  };

  const handleDelete = (keyToDelete: number) => {
    setDeleted(prev => prev.concat(fields[keyToDelete].fieldName));
    // setFields(
    //   fields.filter((_, key) => key !== keyToDelete)
    // );
    let updatedData = fields.filter((_, key) => key !== keyToDelete);
    onUpdate(
      indexBy(f=> f.fieldName, updatedData)
      , deleted)
  };

  return (
    <Segment>

      <h5>Project Parameters</h5>

      <Table basic='very' celled collapsing style={{width: "100%"}}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Label</Table.HeaderCell>
            <Table.HeaderCell>Field Name</Table.HeaderCell>
            <Table.HeaderCell>Get From Source Store</Table.HeaderCell>
            <Table.HeaderCell>Single Select</Table.HeaderCell>
            <Table.HeaderCell>Show Formatted Options</Table.HeaderCell>
            <Table.HeaderCell>Required</Table.HeaderCell>
            <Table.HeaderCell>
              <Button icon size="mini" onClick={handleAdd}>
                <Icon name='plus' />
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {fields.map((fieldData, key) => (

            <Table.Row>
              <Table.Cell>
                <Input
                  style={{width: "100%"}}
                  error={!!(messages[key]?.fiterLabel)}
                  required
                  size="mini"
                  onChange={(e) => handleUpdate(key, "fiterLabel", e.target.value)}
                  value={fieldData.fiterLabel} />
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{width: "100%"}}
                  error={!!(messages[key]?.fieldName)}
                  required
                  size="mini"
                  onChange={(e) => handleUpdate(key, "fieldName", e.target.value)}
                  value={fieldData.fieldName} />
              </Table.Cell>
              <Table.Cell>
                <Checkbox
                  checked={fieldData.getFromSourceStore}
                  error={!!(messages[key]?.getFromSourceStore)}
                  onChange={(e, data) =>
                    handleUpdate(key, "getFromSourceStore", !!data.checked)} />
              </Table.Cell>
              <Table.Cell>
                <Checkbox
                  checked={fieldData.singleSelect}
                  error={!!(messages[key]?.singleSelect)}
                  onChange={(e, data) =>
                    handleUpdate(key, "singleSelect", !!data.checked)} />
              </Table.Cell>
              <Table.Cell>
                <Checkbox
                    checked={fieldData.formattedOptions}
                    error={!!(messages[key]?.formattedOptions)}
                    onChange={(e, data) =>
                        handleUpdate(key, "formattedOptions", !!data.checked)} />
              </Table.Cell>
              <Table.Cell>
                <Checkbox
                    checked={fieldData.required}
                    error={!!(messages[key]?.required)}
                    onChange={(e, data) =>
                        handleUpdate(key, "required", !!data.checked)} />
              </Table.Cell>
              <Table.Cell>
                <Button size="mini" icon  onClick={() => handleDelete(key)}>
                  <Icon  name='trash' />
                </Button>
              </Table.Cell>
            </Table.Row>

          ))}
        </Table.Body>
      </Table>

    </Segment>
  );
};
