import psPlatformClient from "../../psPlatformClient";
import {ModelConfigMapperResult} from "../../ps-models/exa";
import {ModelConfig} from "../../ps-models/exa/model-config/ModelConfig.types";

export const saveModelConfig = async (companyId: string, mapperConfig: ModelConfig) => {
  return (await psPlatformClient<any, any>({
    method: 'post',
    url: `/am/${companyId}/mod-builder`,
    data: mapperConfig
  })).data
}

export const getModelConfig = async (companyId: string): Promise<Record<string, ModelConfig>> => {
  return (await psPlatformClient<any, any>({
    method: 'get',
    url: `/am/${companyId}/mod-builder/`
  })).data
}

export const generateConfig = async (companyId: string, file: File, lineItems: string[]) => {
  const formDataInstance = new FormData();
  formDataInstance.append("companyId", companyId);
  formDataInstance.append("lineItems", JSON.stringify(lineItems));
  formDataInstance.append("file", file);

  return (await psPlatformClient<FormData, any>({
    method: 'post',
    url: `/am/${companyId}/mod-builder/generate-config`,
    headers: {
      'Content-Type': `multipart/form-data;`,
    },
    data: formDataInstance
  }) ).data
}

export const checkModelConfig = async (companyId: string,
                                    excelFile: File,
                                       mapperConfig: string,
                                    updateFileTransferProgress: (percentCompleted: number)=>void) : Promise<ModelConfigMapperResult> => {
  const formDataInstance = new FormData();
  formDataInstance.append("companyId", companyId);
  formDataInstance.append("mapperConfig", mapperConfig);
  formDataInstance.append("file", excelFile);


  return (await psPlatformClient<FormData, any>({
    method: 'post',
    url: `/am/${companyId}/mod-builder/check`,
    headers: {
      'Content-Type': `multipart/form-data;`,
    },
    data: formDataInstance,
    onUploadProgress: ((progressEvent) => {
      if(progressEvent?.total) {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent?.total)
        updateFileTransferProgress(percentCompleted);
      }
    })

  })).data
}