import React, {useEffect, useState} from "react";
import {Button, Header, Segment, Select} from "semantic-ui-react";
import {PsFileUploader} from "../../ui/FileUploader.component";
import {checkModelConfig, generateConfig, saveModelConfig} from "./modelBuilder.client";
import {useCompanyId} from "../../core";
import {ModelConfigMapperResult} from "../../ps-models/exa";
import {ModelConfig} from "../../ps-models/exa/model-config/ModelConfig.types";
import {ModelConfigCreate} from "./ModelConfig";
import {ExcelMappingResultsView} from "./ExcelMappingResultView";
import {ClosableSection} from "../../ClosableSection";
import {LoadingBlock} from "../../ui/Loading";
import {LineItemsStore, StoreQuery} from "../../ps-models/lineitems-store";
import {TimeUnits} from "../../ps-models";
import {LineItemsTableWithFormulas} from "../../lineitems-store/LineItemsTableView";

export const ModelOnboarding: React.FC = () => {
  const companyId = useCompanyId();
  const [file, setFile] = useState<File | null>(null);
  const [result, setResult] = useState<ModelConfigMapperResult>();

  const [loading, setLoading] = useState<string[]>();

  let [store, setStore] = useState<LineItemsStore>();

  useEffect(() => {
    if (result && result.store) {
      setStore(LineItemsStore.deserialize(result.store));
    }
  }, [result]);


  const [config, setConfig] = useState<ModelConfig>({
    name: "Default",
    granularity: "months",
    mappers: []
  });

  const onFileUpload = async (file: File | undefined) => {
    if (!file) {
      return;
    }
    setFile(file);
  };

  const handleGenerateConfig = async () => {

    setLoading( [
      "Analyzing Excel Worksheets",
      "Extracting Line Item Metadata",
      "Finding best candidates for the data set",
      "Generating Configuration",
      "Checking Configuration",
      "Validating Extracted line Items",
      "Generating Data Pipeline",
      "Extracting values from Excel"
    ])

    const lineItems = `Cashflow - Generation
Cashflow - Tariff
Cashflow - Revenues
Cashflow - Provision for bad debt, admin, regulatory costs
Cashflow - O&M Expenses
Cashflow - Insurance
Cashflow - Equipment change
Cashflow - EBITDA
Cashflow - Tax
Cashflow - FCFF
Cashflow - FCFE
Depreciation - Opening Balance (INR)
Depreciation - Depreciation (Cos Act)
Depreciation - Closing Balance
Depreciation - Opening balance - book value
Depreciation - Depreciation (IT Act)
Depreciation - Closing Balance - book value
External Debt - Opening balance
External Debt - Interest
External Debt - EMI
External Debt - Closing balance
Promoter Debt - Opening balance
Promoter Debt - Interest
Promoter Debt - Repayment
Promoter Debt - Closing balance`.split("\n").map(name => name.trim())

    let res = await generateConfig(companyId, file!, lineItems);

    const newConfig = {...config, ...res};

    setConfig(newConfig);

    await handleCheckConfig(newConfig);

    setLoading(undefined);

  }

  const handleCheckConfig = async (modelConfig: any) => {
    setLoading(["Generating Report"])
    const res = await checkModelConfig(companyId, file!,
      JSON.stringify(modelConfig), () => {}
    );

    setResult(res);
    setLoading(undefined);
  };

  const handleSaveConfig = async () => {
    setLoading(["Saving Config"]);
    await saveModelConfig(companyId,  config);
    setLoading(undefined);
  }

  if(loading) {
    return <LoadingBlock loadingMessages={loading} />
  }

  return (
    <Segment>
      <Header as="h1">Model Onboarding</Header>
      <PsFileUploader setFile={onFileUpload} />
      <Button  onClick={handleGenerateConfig}>Generate Config</Button>
      <Button onClick={() => handleCheckConfig(config)}>Check Config</Button>
      <Button floated="right" primary onClick={() => handleSaveConfig()}>Save Config</Button>
      <hr />
      <ClosableSection title={"Model Config"} level='title-bar' opened={false}>
        <ModelConfigCreate config={config} setConfig={setConfig} />
      </ClosableSection>
      {result &&<ClosableSection level='title-bar' title="Data Pipeline" opened={false} >
        <ExcelMappingResultsView result={result} store={store} />
      </ClosableSection>}

      {store && <StorePreview store={store} />}
    </Segment>
  );
};

function StorePreview({store}: {store: LineItemsStore}) {
  let [granularity, setGranularity] = useState<TimeUnits>("years");



    let table = store.query(StoreQuery.all().withTimeIndex(
    store.timeIndex.withGranularity(granularity)
    ), {withMetadata: []} );

    return <Segment>
      <h3>Line Items</h3>
      <Select
        placeholder='Select granularity'
        value={granularity}
        options={[
          {key: 'days', value: 'days', text: 'Day'},
          {key: 'weeks', value: 'weeks', text: 'Weeks'},
          {key: 'months', value: 'months', text: 'Month'},
          {key: 'quarters', value: 'quarters', text: 'Quarters'},
          {key: 'years', value: 'years', text: 'Year'},
        ]}
        onChange={(e, {value}) => {
          setGranularity(value as TimeUnits);
        }}
      />
      <LineItemsTableWithFormulas
        store={store}
        queryResult={table}
      />
    </Segment>
}



