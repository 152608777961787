import {AggregatorMethod} from "../line-item-utils/aggregators";
import {ValueType} from "./LineItemValue.model";
import {TimeUnits} from "../Time.types";



export function buildDailyTimeDef(
  aggregator: AggregatorMethod = "sum",
  spread: SpreadMethod = "middle"
): TimeDefinition {
  return new TimeDefinition(
    aggregator,
    spread,
    "days")
}

export function buildQuarterlyTimeDef(
    aggregator: AggregatorMethod = "sum",
    spread: SpreadMethod = "repeat",
): TimeDefinition {
  return new TimeDefinition(
      aggregator,
      spread,
      "quarters")
}

export function buildMonthlyTimeDef(
  aggregator: AggregatorMethod = "sum",
  spread: SpreadMethod = "repeat",
): TimeDefinition {
  return new TimeDefinition(
    aggregator,
    spread,
    "months")
}

export function buildYearlyTimeDef(
  aggregator: AggregatorMethod = "sum",
  spread: SpreadMethod = "repeat",
): TimeDefinition {
  return new TimeDefinition(
    aggregator,
    spread,
    "years")
}

export function buildTimeDef(
  granularity: TimeUnits,
  aggregator: AggregatorMethod = "sum",
  spread: SpreadMethod = "repeat",
): TimeDefinition {
  return new TimeDefinition(
    aggregator,
    spread,
    granularity)
}

export interface TimeDefinitionDto {
  aggregator: AggregatorMethod
  spread: SpreadMethod
  granularity: TimeUnits
}

export class TimeDefinition {
  constructor(public readonly aggregator: AggregatorMethod,
              public readonly spread: SpreadMethod,
              public readonly granularity: TimeUnits
              ) {
  }

  serialize(): TimeDefinitionDto {
    return {
      aggregator: this.aggregator,
      spread: this.spread,
      granularity: this.granularity
    }
  }
  static deserialize(data: any): TimeDefinition {
    return new TimeDefinition(data.aggregator, data.spread, data.granularity)
  }
}





export const NumberSpread = {
  divide: (value: number,  index: number, timeLength: number): number =>  value / timeLength,
}
export const ValueTypeSpread = {
  repeat: (value: ValueType, index: number, timeLength: number): ValueType => value,
  first: (value: ValueType, index: number, timeLength: number): ValueType => index === 0 ? value : 0,
  last: (value: ValueType,  index: number, timeLength: number): ValueType =>  index === timeLength - 1 ? value : 0,
  middle: (value: ValueType,  index: number, timeLength: number): ValueType => index === Math.floor(timeLength / 2) ? value : 0
}
export type SpreadMethod = keyof typeof NumberSpread | keyof typeof ValueTypeSpread;

export function runSpreadMethod(spread: SpreadMethod, value: ValueType, index: number, timeLength: number): ValueType {
  if (typeof value === "number") {
    return NumberSpread[spread as keyof typeof NumberSpread](value,  index, timeLength);
  } else {
    return ValueTypeSpread[spread as  keyof typeof ValueTypeSpread](value, index, timeLength);
  }
}