import {doSignUp, SignUpData, SignUpPayload} from "../../auth/auth.client";
import {metricsQueue} from "../../../utils/metricsCollector";
import {RegistrationError} from "../../auth/errors";
import {INPUT_FIELD_MAX_CHARACTER_LIMITS} from "../../../utils/globalInputConstants";
import {SignUpUserData} from "../../ps-models";


export interface ESGCampaignSignUpData extends SignUpData {
    userProvidedCompanyName: string;
    campaign: string;
}
export class ESGCampaignSignUpPayload extends SignUpPayload {
    readonly userProvidedCompanyName: string;
    constructor(signUpPayload: ESGCampaignSignUpData) {
        super(signUpPayload);
        const {userProvidedCompanyName} = signUpPayload;
        this.userProvidedCompanyName = userProvidedCompanyName.trim()

        if (!this.userProvidedCompanyName) {
            throw new RegistrationError("Company name is a required field.", "custom/failed-signup-inputs")
        }

        if(this.userProvidedCompanyName.length > INPUT_FIELD_MAX_CHARACTER_LIMITS.companyName
        ) {
            throw new RegistrationError(`Company name should not be longer than ${INPUT_FIELD_MAX_CHARACTER_LIMITS.companyName} characters.`, "custom/failed-signup-inputs")
        }

    }

    get signUpUserData(): SignUpUserData {
        return {
            isNewUser: true,
            providerId: "password",
            profile: {
                email: this.email,
                firstName: this.firstName,
                lastName: this.lastName,
                displayName: this.displayName,
                campaign: 'esg',
                userProvidedCompanyName: this.userProvidedCompanyName
            },
            status: this.status,
        }
    }
}


export async function ESGCampaignSignUp(payload: ESGCampaignSignUpPayload): Promise<void> {
    try {
        await doSignUp(payload)
    } catch (error: any) {
        if (error?.code === "auth/email-already-in-use") {
            console.warn(`Sign Up Exception :: Occurred for ${payload.email}`, error);
        } else {
            console.error(
                `Sign Up Exception :: Occurred for ${payload.email}. They could not sign up.`,
                error
            );
            metricsQueue.add({
                name: "SignUpException",
                value: 1,
                metricCategory: "auth",
            });
        }
        throw error
    }
}