import {
  AmProject,
  WhatIfScenarioConfig,
  WhatIfScenarioInputValues, WhatIfScenarioValuesPerProject,
  WhatIfScenarioRecord
} from "../../ps-types";
import {assocPath, clone, indexBy, prop, values} from "ramda";
import {ClosableSection} from "../../ClosableSection";
import {Button, Grid, Header, Loader, Segment} from "semantic-ui-react";
import React, {useState} from "react";
import {LocatorsMapInput} from "./LocatorsMapInput";
import {getSalesforceConfigKeys, getSalesforceValues} from "./whatIfScenarios.client";
import {useCompanyId} from "../../core";
import {useMessages} from "../../ui/MessagesProvider";
import {authStorage} from "../../auth";
import {getAmProjectConfig} from "../../ps-models";

export function ProjectVersionInputs({config, inputData, updateInputData, projects}: {
  config: WhatIfScenarioConfig,
  inputData: WhatIfScenarioRecord['inputData'],
  projects: AmProject[],
  updateInputData: (scenario: WhatIfScenarioRecord['inputData']) => void,
}) {


  const projectVersionInputs = inputData.projectVersionInputs || {};
  const projectVersions = inputData.projectVersions;
  const companyId = useCompanyId();
  const company = authStorage.getCompany();
  let { collection, namespace } = getAmProjectConfig(company);
  const {info, error} = useMessages();
  const [salesforceLoading, setSalesforceLoading] = useState(false);
  const [salesforceLoadingText, setSalesforceLoadingText] = useState("Load from Salesforce");

  const selectedProjects = projects.filter(p => projectVersions
    .some(pv => pv.projectId === p.id));

  let projectsById = indexBy(prop("id"), selectedProjects);


  const updateProjectValues = (projectId: string, versionId: string, values: WhatIfScenarioInputValues) => {
    let key = projectId + "-" + versionId;
    let projectVersionInputsUpdated = assocPath([key], values, projectVersionInputs);
    updateInputData({...inputData, projectVersionInputs: projectVersionInputsUpdated});
  }

  const loadSaleforceConfig = async (salesforceConfigKey: string, projectVersionInputs: WhatIfScenarioValuesPerProject) => {
    try {
      let salesforceValues = await getSalesforceValues(companyId, salesforceConfigKey);

      console.info("Salesforce Values", salesforceValues)

      // Update the project version inputs

      for (let pv of projectVersions) {
        const key = pv.projectId + "-" + pv.versionId;

        const salesforceValuesForProject = values(salesforceValues[pv.projectId]);

        if (!salesforceValuesForProject) {
          error(`No Salesforce values found for project ${pv.projectId}`);
          continue;
        }

        for (let salesforceValue of salesforceValuesForProject) {

          if(!projectVersionInputs[key]) {
            projectVersionInputs[key] = {};
          }

          projectVersionInputs[key][salesforceValue.valueKey] = {
            valueKey: salesforceValue.valueKey,
            value:  salesforceValue.value
          }
        }

      }
      console.info("Updated Project Version Inputs", projectVersionInputs)
    } catch (e: any) {
      error(`Error calling  Salesforce loader ${salesforceConfigKey} ${e.message}`);
    }
  }

  const loadFromSalesforce = async () => {
    const salesforceConfigs = await getSalesforceConfigKeys(companyId);
    setSalesforceLoading(true)

    let updatedProjectVersionInputs = clone(projectVersionInputs);

    for (let salesforceConfigKey of salesforceConfigs) {
      setSalesforceLoadingText(`Loading ${salesforceConfigKey}`);
      await loadSaleforceConfig(salesforceConfigKey, updatedProjectVersionInputs);
    }

    updateInputData({...inputData, projectVersionInputs: updatedProjectVersionInputs});

    setSalesforceLoadingText("Load from Salesforce");
    setSalesforceLoading(false);
  }

  return <div >
    <div style={{marginBottom: "20px", position: "relative"}}>
    <h3>Projects
      </h3>
      {namespace === "KingEnergy" && <div style={{position: "absolute", top: "0", right: "0"}}>
            {!salesforceLoading && <Button
                primary
                onClick={loadFromSalesforce}
                disabled={salesforceLoading}
            >
                Load from Salesforce
            </Button>}

            {salesforceLoading &&  <Loader
                size="tiny"
                position="right"
                active inline>{salesforceLoadingText}</Loader>}
        </div>}
    </div>

    <div style={{marginBottom: "20px"}}>
    {projectVersions.map(pv => {
      let p = projectsById[pv.projectId];

      if(!p) return null;

      let versionDesc = p.versions.find(v => v.versionId === pv.versionId)?.title;
      let values = projectVersionInputs[pv.projectId + "-" + pv.versionId] || {};
      return <div>
        <ClosableSection opened={false} level="title-bar" title={<strong>{p.name} Version: {versionDesc}</strong>}>
          <div style={{padding: "10px"}}>
          <Grid>
            {config && <LocatorsMapInput
                inputValues={values}
                mappingConfig={config.mappingConfig}
                onValuesUpdated={(values) => {
                  updateProjectValues(p.id, pv.versionId, values);
                }}
            />}
          </Grid>
          </div>
        </ClosableSection>
      </div>
    })
    }
    </div>
  </div>
}