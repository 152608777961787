import React, { useState, useEffect } from 'react';
import { fetchDbActionsLogs } from '../admin.client';
import { DbActionsLog } from '../../ps-types/DbActionsLog';

const DbActionsLogViewer = ({ collectionName, dbActionLogName }: { collectionName: string, dbActionLogName: string }) => {
    const [logs, setLogs] = useState<DbActionsLog[]>([]);
    const [loading, setLoading] = useState(true);
    const [expandedLogs, setExpandedLogs] = useState<Set<number>>(new Set());

    useEffect(() => {
        // Fetch logs on component mount
        fetchDbActionsLogs(collectionName, dbActionLogName)
            .then((data) => {
                //@ts-ignore
                setLogs(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching logs:', error);
                setLoading(false);
            });
    }, [collectionName, dbActionLogName]);

    if (loading) {
        return <div>Loading logs...</div>;
    }

    const toggleExpand = (index: number) => {
        setExpandedLogs((prev) => {
            const newExpandedLogs = new Set(prev);
            if (newExpandedLogs.has(index)) {
                newExpandedLogs.delete(index);
            } else {
                newExpandedLogs.add(index);
            }
            return newExpandedLogs;
        });
    };

    return (
        <div>
            <h2>DB Actions Logs</h2>
            {logs.length === 0 ? (
                <p>No logs available</p>
            ) : (
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                    <tr>
                        <th style={{ border: '1px solid #ccc', padding: '8px' }}>DB Action</th>
                        <th style={{ border: '1px solid #ccc', padding: '8px' }}>Status</th>
                        <th style={{ border: '1px solid #ccc', padding: '8px' }}>Timestamp</th>
                        <th style={{ border: '1px solid #ccc', padding: '8px' }}>Message Response</th>
                    </tr>
                    </thead>
                    <tbody>
                    {logs.map((log: DbActionsLog, index) => (
                        <tr key={index}>
                            <td style={{ border: '1px solid #ccc', padding: '8px' }}>{log.dbActionName}</td>
                            <td style={{ border: '1px solid #ccc', padding: '8px' }}>{log.status}</td>
                            <td style={{ border: '1px solid #ccc', padding: '8px' }}>{new Date(log.timestamp).toLocaleString()}</td>
                            <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                                {expandedLogs.has(index) ? log.messageResponse : `${log.messageResponse.substring(0, 500)}.`}
                                {log.messageResponse.length > 500 && (
                                    <button onClick={() => toggleExpand(index)}>
                                        {expandedLogs.has(index) ? 'View Less' : 'View More'}
                                    </button>
                                )}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default DbActionsLogViewer;