import {Route} from "react-router-dom";
import KeyManagementPage from "./key-management/KeyManagement.page";
import {MainBoard} from "./index";
import {Integration} from "./integrations";
import React from "react";
import {NewAuthWrapper} from "../auth";
import {LineItemsEditor} from "./line-items-editor";
import {LineItemsBatchUpdater} from "./line-items-batch-updater";
import {ListOfSites} from "./boards/demo/ListOfSites";
import {Projects} from "./projects/Projects.page";
import {ProjectTemplate} from "./projects/ProjectTemplate.page";
import {ProjectDetailPage} from "./projects/ProjectDetail.page";
import {DashboardBoardBuilderPage} from "./builder";
import {Dashboards} from "./builder/Dashboards.page";
import {Reports} from "./reports/Reports.page";
import {ReportDetailPage} from "./reports/ReportDetail.page";
import {DashboardMainPage} from "./builder/DashboardMain.page";
import {LineItemsTimeSeriesTablePeformanceCheck} from "./performance-check/LineItemsTimeSeriesTablePeformanceCheck";
import {StreamResultsPerformanceTest} from "./performance-check/StreamResultsPeformanceCheck";
import {WhatIfScenarioDetailPage} from "./whatif-scenarios/WhatIfScenarioDetail.page";
import {MultiProjectUploadTemplate} from "./projects/MultiProjectUploadTemplate.page";
import {AmCompanyNewModelPage} from "./am-company-models/AmCompanyNewModel.page";
import {Invoices} from "./invoicing/Invoices.page";
import {InvoiceDetailPage} from "./invoicing/InvoiceDetail.page";
import {loadCompanyModule} from "./builder/DashboardConfigServiceRegistry";
import {Layout} from "./layout";
import {ESGProjectTemplate} from "./projects/ESGProjectTemplate.page";
import {ModelOnboarding} from "./model-config";

function CompanyRoutes() {
  let module = loadCompanyModule();

  return <>
    {module.getRoutes()}
  </>
}

const getRoutes =  () => {


  return [
    <Route exact path='/ps/:companyId/am/check/p1'
           render={ () => <NewAuthWrapper>
             <Layout contentWide><LineItemsTimeSeriesTablePeformanceCheck /></Layout></NewAuthWrapper> } />,


    <Route path='/ps/:companyId/ext' >
      <NewAuthWrapper>
        <CompanyRoutes />
      </NewAuthWrapper>
    </Route>,

    <Route exact path='/ps/:companyId/model-config'
           render={ () => <NewAuthWrapper>
             <Layout><ModelOnboarding /></Layout></NewAuthWrapper> } />,

    <Route exact path='/ps/:companyId/am/check/p2'
           render={ () => <NewAuthWrapper>
             <Layout contentWide><StreamResultsPerformanceTest /></Layout></NewAuthWrapper> } />,

    <Route exact path='/ps/:companyId/am/board-builder'
           render={ () => <NewAuthWrapper>
             <Layout><Dashboards /></Layout></NewAuthWrapper> } />,
    <Route path='/ps/:companyId/am/dashboard'
           render={ (props) => <NewAuthWrapper>
             <Layout contentWide>
               <DashboardMainPage />
             </Layout></NewAuthWrapper> } />,
    <Route exact path='/ps/:companyId/am/board-builder/:dashboardId'
           render={ (props) => <NewAuthWrapper>
             <Layout contentWide>
               <DashboardBoardBuilderPage
                 dashboardId={props.match.params.dashboardId}
               />
             </Layout></NewAuthWrapper> } />,
    <Route exact path='/ps/:companyId/am/projects'
           render={ () => <NewAuthWrapper>
             <Layout><Projects /></Layout></NewAuthWrapper> } />,

    <Route exact path='/ps/:companyId/am/projects/:subPage'
           render={ () => <NewAuthWrapper>
             <Layout><Projects /></Layout></NewAuthWrapper> } />,
      <Route path='/ps/:companyId/am/esg' exact
             render={ (props) => <NewAuthWrapper>
                 <Layout><ESGProjectTemplate /></Layout>
             </NewAuthWrapper> } />,
    <Route path='/ps/:companyId/am/project/:projectId/new-version' exact
           render={ (props) => <NewAuthWrapper>
             <Layout><ProjectTemplate projectId={props.match.params.projectId ?? ''} /></Layout>
           </NewAuthWrapper> } />,
    <Route path='/ps/:companyId/am/new-projects' exact
           render={ () => <NewAuthWrapper>
             <Layout><MultiProjectUploadTemplate/></Layout>
           </NewAuthWrapper> } />,
    <Route path='/ps/:companyId/am/project/:projectId' exact
           render={ (props) => <NewAuthWrapper>
             <Layout> <ProjectDetailPage projectId={props.match.params.projectId ?? ''} /></Layout>
           </NewAuthWrapper> } />,

  <Route path='/ps/:companyId/am/company/model/new-model' exact
         render={ (props) => <NewAuthWrapper>
             <Layout><AmCompanyNewModelPage /></Layout>
         </NewAuthWrapper> } />,
    <Route path='/ps/:companyId/am/what-if-scenario/:scenarioId' exact
           render={ (props) => <NewAuthWrapper>
             <Layout> <WhatIfScenarioDetailPage scenarioId={props.match.params.scenarioId} /></Layout>
           </NewAuthWrapper> } />,


    <Route exact path='/ps/:companyId/am/reports'
           render={ () => <NewAuthWrapper>
             <Layout><Reports /></Layout></NewAuthWrapper> } />,

    <Route exact path='/ps/:companyId/am/report/:reportId'
            render={ (props) => <NewAuthWrapper>
              <Layout contentWide>
                <ReportDetailPage reportId={props.match.params.reportId} />
              </Layout></NewAuthWrapper> } />,

      <Route exact path='/ps/:companyId/am/invoices'
             render={ () => <NewAuthWrapper>
                 <Layout><Invoices /></Layout></NewAuthWrapper> } />,

      <Route exact path='/ps/:companyId/am/invoice/:invoiceId'
             render={ (props) => <NewAuthWrapper>
                 <Layout contentWide>
                     <InvoiceDetailPage invoiceId={props.match.params.invoiceId} />
                 </Layout></NewAuthWrapper> } />,

    <Route exact path='/ps/:companyId/key-management'
           render={ () => <NewAuthWrapper><KeyManagementPage /></NewAuthWrapper> } />,
    <Route path='/ps/:companyId/assets-management/b/'
           render={ () => <NewAuthWrapper><Layout contentWide={true}><MainBoard /></Layout></NewAuthWrapper> } />,
    <Route exact path='/ps/:companyId/assets-management/integrations'
           render={ () => <NewAuthWrapper><Layout><Integration /></Layout></NewAuthWrapper> } />,
    <Route exact path='/ps/:companyId/assets-management/line-items-editor'
            render={ () => <NewAuthWrapper><Layout contentWide><LineItemsEditor /></Layout></NewAuthWrapper> } />,
    <Route exact path='/ps/:companyId/assets-management/line-items-batch-updater'
             render={ () => <NewAuthWrapper><Layout><LineItemsBatchUpdater /></Layout></NewAuthWrapper> } />,
    <Route exact path='/ps/:companyId/assets-management/list-of-sites'
             render={ () => <NewAuthWrapper><Layout><ListOfSites /></Layout></NewAuthWrapper> } />
  ]
}

export default getRoutes;