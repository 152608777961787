import {Button, Dropdown, Form, FormField, Grid, Input, Label, Table} from "semantic-ui-react";
import React from "react";
import {Mapper, ModelConfig, ValueLine} from "../../ps-models/exa/model-config/ModelConfig.types";

export const ModelConfigCreate = ({config, setConfig}: { config: ModelConfig; setConfig: (conf: ModelConfig) => void }) => {
  const addMapper = (type: "parameter" | "lineItem") => {
    const newMapper: any =
      type === "parameter"
        ? {mapperType: "parameter", label: ""}
        : {
          mapperType: "lineItem",
          timeDefinition: {granularity: "months", relativeTo: ""},
          timeLine: {label: "", direction: "right"},
          valueLine: {workSheet: "", label: "", direction: "right"},
        };
    setConfig({...config, mappers: [...config.mappers, newMapper]});
  };

  const updateMapper = <K extends keyof Mapper>(index: number, field: string, value: any) => {
    const updatedMappers = [...config.mappers];
    updatedMappers[index] = {...updatedMappers[index], [field]: value};
    setConfig({...config, mappers: updatedMappers});
  };

  const deleteMapper = (index: number) => {
    const updatedMappers = config.mappers.filter((_, i) => i !== index);
    setConfig({...config, mappers: updatedMappers});
  };

  return (
    <div style={{padding: "20px"}}>
      <Form style={{marginBottom: "10px"}}>
        <Form.Field>
          <label>Granularity</label>
          <Dropdown
            placeholder="Select Granularity"
            selection
            options={[
              {key: "years", text: "Years", value: "years"},
              {key: "months", text: "Months", value: "months"},
            ]}
            value={config.granularity}
            onChange={(e, {value}) => setConfig({...config, granularity: value as ModelConfig["granularity"]})}
          />
        </Form.Field>
      </Form>
        <Button size="mini" onClick={() => addMapper("parameter")} style={{marginBottom: "10px"}}>
          Add Parameter Config
        </Button>
        <Button size="mini" onClick={() => addMapper("lineItem")} style={{marginBottom: "10px"}}>
          Add LineItem Config
        </Button>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Mapper Type</Table.HeaderCell>

            <Table.HeaderCell>Details</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {config.mappers.map((mapper, index) => (
            <Table.Row key={index}>
              <Table.Cell>{mapper.mapperType}</Table.Cell>
              <Table.Cell>
                <Input
                  placeholder="Line Item Name"
                  value={mapper.lineItemName}
                  onChange={(e) => updateMapper(index, "lineItemName", e.target.value)}
                />
                {mapper.mapperType === "parameter" ? (
                  <Input
                    placeholder="Label"
                    value={mapper.label}
                    onChange={(e) =>
                      updateMapper(index, "label", e.target.value)
                    }
                  />
                ) : (
                  <div>
                    <Grid>
                      <Grid.Row columns={2}>
                        <Grid.Column>
                          <strong>Line Item Config</strong>
                          <FormField>
                            <Label>Label</Label>
                            <Input
                              placeholder="ValueLine Label"
                              value={mapper.valueLine?.label}
                              onChange={(e) =>
                                updateMapper(index, "valueLine", {
                                  ...mapper.valueLine,
                                  label: e.target.value,
                                })
                              }
                            />
                          </FormField>
                          <FormField>
                            <Label>Direction</Label>
                            <Dropdown
                              labeled
                              selection
                              options={[
                                {key: "right", text: "Right", value: "right"},
                                {key: "down", text: "Down", value: "down"},
                              ]}
                              value={mapper.valueLine?.direction}
                              onChange={(e, {value}) =>
                                updateMapper(index, "valueLine", {
                                  ...mapper.valueLine,
                                  direction: value as ValueLine["direction"],
                                })
                              }
                            />
                          </FormField>
                          <FormField>
                            <Label>Worksheet</Label>
                            <Input
                              placeholder="ValueLine Worksheet"
                              value={mapper.valueLine?.workSheet}
                              onChange={(e) =>
                                updateMapper(index, "valueLine", {
                                  ...mapper.valueLine,
                                  workSheet: e.target.value,
                                })
                              }
                            />
                          </FormField>
                        </Grid.Column>
                        <Grid.Column>
                          <strong>Timeline Config</strong>
                          <FormField>
                            <Label>Label</Label>
                            <Input
                              placeholder="Timeline Label"
                              value={mapper.timeLine?.label}
                              onChange={(e) =>
                                updateMapper(index, "timeLine", {
                                  ...mapper.timeLine,
                                  label: e.target.value,
                                })
                              }
                            />
                          </FormField>
                          <FormField>
                            <Label>Direction</Label>
                            <Dropdown
                              placeholder="Timeline Direction"
                              selection
                              options={[
                                {key: "right", text: "Right", value: "right"},
                                {key: "down", text: "Down", value: "down"},
                              ]}
                              value={mapper.timeLine?.direction}
                              onChange={(e, {value}) =>
                                updateMapper(index, "timeLine", {
                                  ...mapper.timeLine,
                                  direction: value
                                })
                              }
                            />
                          </FormField>
                          <FormField>
                            <Label>Time Relative to</Label>
                            <Input
                              placeholder="Relative To"
                              value={mapper.timeDefinition?.relativeTo}
                              onChange={(e) =>
                                updateMapper(index, "timeDefinition", {
                                  ...mapper.timeDefinition,
                                  relativeTo: e.target.value,
                                })
                              }
                            />
                          </FormField>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </div>
                )}
              </Table.Cell>
              <Table.Cell>
                <Button color="red" onClick={() => deleteMapper(index)}>
                  Delete
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};