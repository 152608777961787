import {formatDistance} from "date-fns";
import {NavLink, useHistory, useParams} from "react-router-dom";
import {PS_URLS, useCompanyId, useCurrentUser, usePlatformUrls} from "../../core";
import {AmProject} from "../../ps-types";
import {DeleteButton} from "../../ui/crud/DeleteButton.component";
import {buildEntityDef, EntityCrud} from "../../ui/crud/EntityCrud.component";
import {createAmProject, deleteAmProject, getAmProjects} from "./amProject.client";
import {Button, Header, Tab} from "semantic-ui-react";
import {TemplateConfig} from "./TemplateConfig";
import {CustomImports} from "./CustomImports";
import {WhatIfScenariosPage} from "../whatif-scenarios/WhatIfScenarios.page";
import {indexBy} from "ramda";
import {CompanyLevelConfig} from "../../company/CompanyLevelConfig.page";
import {ProjectModelReprocessor} from "./ProjectModelReprocessor.page";
import {ProjectSync} from "./ProjectSync";
import { SyncSpvs } from "./SpvSync";
import {AmCompanyModelsListPage} from "../am-company-models/AmCompanyModelsList.page";
import {ReactNode} from "react";
import {ModelOnboarding} from "../model-config";


function timeAgo(value: string) {
  return value ? formatDistance(new Date(value), new Date(), {addSuffix: true}) : "";
}

export function ProjectLink({project: p}: {project: AmProject}) {
  const { amProjectNewVersion, amProjectDetails } = usePlatformUrls();

  return p.versions.length > 0 ?
    <NavLink to={() => amProjectDetails(p.id)}>{p.name}</NavLink>:
    <NavLink to={() => amProjectNewVersion(p.id)}>{p.name}</NavLink>
}

function DeleteBtn({entityName, entity, handleDelete}: {entityName: string, entity: any, handleDelete: (entity: any) => void}) {
  const content =`Are you sure you want to delete the ${entityName} '${entity["name"]}'?`;

  return <DeleteButton
    content={content}
    onDelete={() => handleDelete(entity)}
  />
}

export interface ProjectListingOptions {
  title?: string
  getProjects?: () => Promise<AmProject[]>;
}


export function Projects(options: ProjectListingOptions = {}) {
  const history = useHistory();

  const companyId = useCompanyId();
  const user = useCurrentUser();
  let isAdmin = user.isAdmin();

  const getEntities = options.getProjects || (() => getAmProjects(companyId));
  const createEntity =  async () => {
    const project = await createAmProject(companyId);
    history.push(PS_URLS(companyId).amProjectNewVersion(project.id!))
  }
  const createMultipleEntity =  async () => {
    history.push(PS_URLS(companyId).amMultipleProjectNewVersion())
  }

  let subPage = useParams<{subPage: string}>().subPage || 'projects';

  let tabIndex: Record<string, number> = {
    projects: 0,
    "what-if": 1,
    "models": 2,
  }
  if(isAdmin){
    tabIndex = {
      projects: 0,
      "what-if": 1,
      "models": 2,
      config: 3,
      imports: 4,
      "project-sync": 5,
      "spv-sync": 6,
      "template-config": 7,
      "project-version-model-reprocessor": 8
    }
  }

  let reverseTabIndex: Record<number, string> = indexBy((k) => tabIndex[k], Object.keys(tabIndex));

  const deleteEntity = (project: any) => deleteAmProject(companyId, project.id);

  let def = buildEntityDef(
    {
      entityName: "Project",
      title: options.title || "My Projects",
      getEntities,
      deleteEntity,
      table: {
        tableComponent: 'dataGrid',
        deleteButton: (props)=> (<DeleteBtn {...props} />)
      }
    },
    {
      name: {
        table: {
          render: (_value: string, project) =>
            <ProjectLink project={project as AmProject} />
        }
      },
      createdAt: {
        title: "CREATED",
        create: { type: "hidden" },
        table: { format: timeAgo }
      },
      "defaultVersionData.id": {
        title: "PROJECT ID",
        table: {
          format: (value: string, project) => {
            if(value){
              return value;
            } else {
              return project.id;
            }
          }
        },
        create: { type: "hidden" },
      },
      defaultVersion: {
        title: "DEFAULT VERSION",
        table: {
          format: (value: string, project) => project.versions.find((v: any) => v.versionId === value)?.title || ""
        },
        create: { type: "hidden" },
      },
      "Default Version Description": { // This is a dummy field name
        title: "DESCRIPTION",
        table: {
          format: (_value: string, project) => {
            const defaultVer = project.versions.find((v: any) => v.versionId === project.defaultVersion)
            let description = defaultVer?.description;
            return `${description || ""}`
          }
        },
        create: { type: "hidden" },
      },
      fileProcessedAt: {
        title: "EXCEL LAST PROCESSED",
        create: { type: "hidden" },
        table: { format: timeAgo }
      }
    }
  );

  let panes: Record<string, any>[] = [
    {
      active: subPage === 'projects',
      menuItem: 'Projects', render: () => <Tab.Pane>
        <Button primary
                floated={"right"}
                style={{marginBottom: "1em"}}
                size={"small"}
                onClick={createEntity}
        >Add Project</Button>
         <Button primary
                floated={"right"}
                style={{marginBottom: "1em"}}
                size={"small"}
                onClick={createMultipleEntity}
        >Add Multiple Projects</Button>
        <EntityCrud entityDef={def} />
      </Tab.Pane>
    },
    {
      active: subPage === 'what-if',
      menuItem: 'What If Scenarios',
      render: () => <Tab.Pane>
        <WhatIfScenariosPage  />
      </Tab.Pane>
    },
    {
      active: subPage === 'models',
      menuItem: 'Models',
      render: () => <Tab.Pane>
        <AmCompanyModelsListPage />
      </Tab.Pane>
    },
  ]

  if(isAdmin) {
     panes = [
       ...panes,
       {
         active: subPage === 'config',
         menuItem: 'Config',
         render: () => <Tab.Pane>
           <CompanyLevelConfig />
         </Tab.Pane>
       },
       {
         active: subPage === 'imports',
         menuItem: 'Imports',
         render: () => <Tab.Pane>
           <CustomImports  />
         </Tab.Pane>
       },
       {
         active: subPage === 'project-sync',
         menuItem: 'Project Sync',
         render: () => <Tab.Pane>
           <ProjectSync />
         </Tab.Pane>
       },
       {
         active: subPage === 'spv-sync',
         menuItem: 'Sync SPVs',
         render: () => <Tab.Pane>
           <SyncSpvs />
         </Tab.Pane>
       },
       {
         active: subPage === 'template-config',
         menuItem: 'Template Config', render: () => <Tab.Pane>
           <ModelOnboarding />
         </Tab.Pane>
       },
       {
         active: subPage === 'project-version-model-reprocessor',
         menuItem: 'Reprocess Project Version Models',
         render: () => <Tab.Pane>
           <ProjectModelReprocessor />
         </Tab.Pane>
       }
     ]
  }

  return <div>
    <Tab
      onTabChange={(_, { activeIndex }) => {
        console.log("index", activeIndex, reverseTabIndex[activeIndex as number])
        history.push(PS_URLS(companyId).amProjects(reverseTabIndex[activeIndex as number]))}
      }
      panes={panes} activeIndex={tabIndex[subPage]} />
  </div>
}
