import {ValueType} from "../line-items";
import {StoreType} from "./LineItemsStore";
import {TimeUnits} from "../Time.types";
import {TimeIndex, TimeIndexDto} from "../Time.model";
import {LineItemFieldFilter} from "../../ps-types";

function removeDuplicates(array: string[]): string[] {
  return Array.from(new Set(array));
}


export class PersistenceQuery {
  constructor(
    public storeIds: string[] = [],
    public lineItems: string[] = [],
    public _calculatedLineItems: string[] = [],
    public _havingParamValues: Record<string, ValueType | ValueType[]> = {},
    public _selectsFirstParamValues: string[] = [],
    public _havingImports: string[] = [],
    public _metadataOnly?: 'stores' | 'lineItems' | 'lineItemsMetadataWithSingleTimeSlot',
    public _selectParams: string[] = [],
    public _storeType: StoreType | "any" = "document",
    public _import: string[] = [],
    public _withTimeIndex?: TimeIndex,
    public _withGranularity?: TimeUnits,
    public _queryName: string = "",
    public _selectLineItemsHavingFieldValues?: Record<string, ValueType | ValueType[]>,
    public _selectLineItemsHavingFields?: LineItemFieldFilter[],
    // @TODO: Remove _materializedCalculatedLineItems, once migration of timed line items is done.
    // By default Calculated Line Items are materialized i.e. they are converted to  Raw line items in the aggregate, with this option false, they will be loaded with their original type
    public _materializedCalculatedLineItems?: boolean,
    ) {
  }

  withQueryName(name: string) {
    this._queryName = name;
    return this;
  }

  withLineItems(lineItems: string[]) {
    this.lineItems = removeDuplicates(lineItems);
    return this;
  }

  withTimeIndex(timeIndex: TimeIndex) {
    this._withTimeIndex = timeIndex;
    return this;
  }

  withGranularity(granularity: TimeUnits) {
    this._withGranularity = granularity;
    return this;
  }

  havingImports(imports: string[]) {
    this._havingImports = imports;
    return this;
  }

  havingParameterValues(parameterValues: Record<string, ValueType | ValueType[]>) {
    this._havingParamValues = parameterValues;
    return this;
  }

  selectsFirstParamValues(parameters: string[]) {
    this._selectsFirstParamValues = parameters;
    if(parameters.length>0){
      this.selectSourceParams(removeDuplicates([...this._selectParams,...parameters]));
    }
    return this;
  }

  withStoreType(docType: "document" | "template" | "any") {
      this._storeType = docType;
      return this;
  }


  withStoreIds(storeIds: string[]) {
   this.storeIds = removeDuplicates(storeIds);
    return this;
  }

  selectSourceParams(selectMetadata: string[]) {
    this._selectParams = removeDuplicates(selectMetadata)
    return this;
  }

  metadataOnly(params?: string[]) {
    this.storesMetadata(params);
    return this;
  }

  storesMetadata(params?: string[]) {
    this._metadataOnly = 'stores';
    if(params) {
      this.selectSourceParams(params)
    }
    return this;
  }

  lineItemsMetadata(params?: string[]) {
    this._metadataOnly = 'lineItems';
    if(params) {
      this.selectSourceParams(params)
    }
    return this;
  }

  lineItemsMetadataWithSingleTimeSlot(params?: string[]) {
    this._metadataOnly = 'lineItemsMetadataWithSingleTimeSlot';
    if(params) {
      this.selectSourceParams(params)
    }
    return this;
  }

  import(importIds: string[]) {
    this._import = importIds;
    return this;
  }

  withMaterializedCalculatedLineItems(materializedTimed: boolean) {
    this._materializedCalculatedLineItems = materializedTimed;
    return this;
  }
// @TODO:AM49 Deprecate in favor of selectLineItemsHavingFields
  selectLineItemsHavingFieldValues(fieldMap: Record<string, ValueType | ValueType[]>) {
    this._selectLineItemsHavingFieldValues = fieldMap;
    return this;
  }

  selectLineItemsHavingFields(fieldFilters: LineItemFieldFilter[]) {
    this._selectLineItemsHavingFields = fieldFilters;
    return this;
  }

  serialize(): PersistenceQueryDto {
    return {
      storeIds: this.storeIds,
      lineItems: this.lineItems,
      _calculatedLineItems: this._calculatedLineItems,
      _havingParamValues: this._havingParamValues,
      _selectsFirstParamValues: this._selectsFirstParamValues,
      _havingImports: this._havingImports,
      _metadataOnly: this._metadataOnly,
      _selectParams: this._selectParams,
      _storeType: this._storeType,
      _import: this._import,
      _withTimeIndex: this._withTimeIndex?.serialize(),
      _withGranularity: this._withGranularity,
      _queryName: this._queryName,
      _selectLineItemsHavingFieldValues: this._selectLineItemsHavingFieldValues,
      _selectLineItemsHavingFields: this._selectLineItemsHavingFields,
      _materializedCalculatedLineItems: this._materializedCalculatedLineItems,
    };
  }

  static deserialize(data: PersistenceQueryDto): PersistenceQuery {
    return  Object.assign(new PersistenceQuery(), {...data,...(data._withTimeIndex ? {_withTimeIndex: TimeIndex.deserialize(data._withTimeIndex)}: {})});
  }

  withCalculatedLineItems(calculatedLineItems: string[]) {
    this._calculatedLineItems = calculatedLineItems;
    return this;
  }
}

export function pQuery() {
  return new PersistenceQuery()
}

export interface PersistenceQueryDto {
  storeIds: string[],
  lineItems: string[],
  _calculatedLineItems: string[],
  _havingParamValues: Record<string, ValueType | ValueType[]>,
  _selectsFirstParamValues: string[],
  _havingImports: string[],
  _metadataOnly?: 'stores' | 'lineItems' | 'lineItemsMetadataWithSingleTimeSlot',
  _selectParams: string[],
  _storeType: StoreType | "any",
  _import: string[],
  _withTimeIndex?: TimeIndexDto,
  _withGranularity?: TimeUnits,
  _queryName: string,
  _selectLineItemsHavingFieldValues?: Record<string, ValueType | ValueType[]>,
  _selectLineItemsHavingFields?: LineItemFieldFilter[]
  _materializedCalculatedLineItems?: boolean,
}