import {useState} from "react";
import {collectionBuildIndexes, collectionFindDuplicates, fetchCollectionFields} from "./admin.client";
import {Button, Dropdown, Icon} from "semantic-ui-react";
import DbActionsLogViewer from "./dbActions-log/DbActionsLogViewer";
import * as React from "react";

interface DbActionsDashboardProps {
    collections: any[] | [];
    selectPlaceHolder: string;
    title: string;
}

const DbActionsDashboard: React.FC<DbActionsDashboardProps> = ({collections, selectPlaceHolder, title}) => {

    const [selectedCollection, setSelectedCollection] = useState('');
    const [buildIndexesCompleted, setBuildIndexesCompleted] = useState(0);
    const [collectionFields, setCollectionFields] = useState<any[]>([]);
    const [collectionField, setCollectionField] = useState('');

    const handleBuildIndexes = async () => {
        collectionBuildIndexes(selectedCollection, collectionField)
            .then((res) => {
                setBuildIndexesCompleted(prev => prev + 1); // Update state to trigger re-render
            });
    }

    const handleFindDuplicates = async () => {
        collectionFindDuplicates(selectedCollection, collectionField)
            .then((res) => {
                setBuildIndexesCompleted(prev => prev + 1);
            });
    }

    const handleCollectionSelectorClick = async (collection: any) => {
        // check correct collection name
        setSelectedCollection(collection.name);

        fetchCollectionFields(collection.name).then((data) => {
            const collectionFieldsArray = Object.values(data);
            let fieldList = collectionFieldsArray.map((field: any) => {
                return {
                    id: field,
                    label: field,
                    name: field,
                };
            });
            setCollectionFields(fieldList);
        });
    }

    const handleFieldSelectorClick = async (field: any) => {
        setCollectionField(field.id);
    }

    return <>
        <h2>{title}</h2>
        <SelectorDropdown placeHolder={selectPlaceHolder} items={collections} onClick={handleCollectionSelectorClick}
                          currentItemId={selectedCollection}/>

        {selectedCollection &&
            <SelectorDropdown items={collectionFields} placeHolder={'Select a field'} currentItemId={collectionField}
                              onClick={handleFieldSelectorClick}/>}

        <Button disabled={!collectionField || collectionField.trim().length === 0} primary size="medium"
                labelPosition="left" icon onClick={handleBuildIndexes}>
            "Build Indexes"
            <Icon name='database'/>
        </Button>

        <Button disabled={!collectionField || collectionField.trim().length === 0} primary size="medium"
                labelPosition="left" icon onClick={handleFindDuplicates}>
            "Find Duplicates"
            <Icon name='database'/>
        </Button>

        {selectedCollection !== '' &&
            <DbActionsLogViewer collectionName={selectedCollection} dbActionLogName='buildIndexes'
                                key={buildIndexesCompleted}/>}
    </>;
}

export default DbActionsDashboard;

interface SelectorDropdownProps {
    currentItemId?: string;
    items: any[];
    onClick: (item: any) => void;
    placeHolder: string;
}

const SelectorDropdown: React.FC<SelectorDropdownProps> = ({items,  onClick, currentItemId, placeHolder }) => {
    const selectedItem  = (item: any) => {
        if (currentItemId) {
            return item.id === currentItemId;
        }
        return false;
    };

    return (
        <Dropdown
            placeholder={placeHolder}
            selection
            search={true}
            deburr
            loading={items.length === 0}
            value={currentItemId || ""}
            options={items
                .filter((item) => item.name !== "Acme INC")
                .map((item: any) => {
                    return {
                        text: item.label || `UNDEFINED: ${item.id}`,
                        value: item.name,
                        key: item.id,
                        selected: selectedItem(item),
                        onClick: () => onClick(item),
                    };
                })}
        />
    );
};